/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { Button, Popover, ListItem, Divider, SwipeableDrawer } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import Popup from '../../../Product/components/organisms/Popup'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const { isAuthenticated, logout, userCount } = useContext(AuthContext)
    const [openModal, setModal] = useState(false)

    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
    })

    const changeDialogStatus = () => {
        setModal(!openModal)
    }

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleClose = (history, path) => {
        setanchormenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }

    const logoutUser = () => {
        logout()
        history.push('/login')
        window.location.reload()
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <>
                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex justify-content-between align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Logo className="headerLogo" />
                            </div>
                            <HeaderSearch />
                            <div className="headRt d-flex justify-content-start align-items-center nowrap">
                                <ul className="d-flex justify-content-start align-items-center">
                                    {global.storeConfigration?.external_schedule_pickup_link
                                        ?.value && (
                                        <li>
                                            <a
                                                href="#"
                                                onClick={() => setModal(true)}
                                                className="cvc-link mr-2"
                                            >
                                                Schedule Pickup
                                            </a>
                                        </li>
                                    )}
                                    <li>
                                        <NavLink activeClassName="active" to="/auctions">
                                            Auctions
                                        </NavLink>
                                    </li>
                                    {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                        <li>
                                            <NavLink activeClassName="active" to="/gallery">
                                                Buy Now
                                            </NavLink>
                                        </li>
                                    ) : null}
                                    <li>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            How it works
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink activeClassName="active" to="/faq">
                                            FAQ
                                        </NavLink>
                                    </li>
                                    <Headernavmenu type="desk" />

                                    {isAuthenticated ? (
                                        <>
                                            {global.storeConfigration?.disable_buynow?.value !==
                                            '1' ? (
                                                <li>
                                                    <CartIcon />
                                                </li>
                                            ) : null}
                                            <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                >
                                                    My Account
                                                    <span className="material-icons">
                                                        account_circle
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(anchormenu)}
                                                    anchorEl={anchormenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/mybids',
                                                                )
                                                            }
                                                        >
                                                            Dashboard
                                                        </ListItem>
                                                        {/* <ListItem
                                                        button
                                                        onClick={() => handleClose(history, 'cart')}
                                                    >
                                                        Cart
                                                    </ListItem> */}

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/profile',
                                                                )
                                                            }
                                                        >
                                                            Profile
                                                        </ListItem>
                                                        <ListItem button onClick={logoutUser}>
                                                            Logout
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    ) : (
                                        <li>
                                            <NavLink activeClassName="active" to="/login">
                                                Login
                                            </NavLink>{' '}
                                            /{' '}
                                            <NavLink activeClassName="active" to="/sign-up">
                                                Sign up
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="mainHeader mobHeader noPrint customContainer d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex justify-content-start">
                            <Logo className="headerLogo" />
                        </div>
                        <div className="headRt">
                            <ul className="d-flex justify-content-start ml-auto align-items-center">
                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('top', true)}
                                    >
                                        <span className="material-icons">search</span>
                                    </Button>
                                </li>

                                {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                    <li>
                                        <CartIcon />
                                    </li>
                                ) : null}

                                {/* {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                    <li className="headCart">
                                        <Button
                                            className="respNavBtn"
                                            onClick={() => handleRedirectInternal(history, 'cart')}
                                        >
                                            <span className="material-icons">shopping_cart</span>
                                        </Button>
                                    </li>
                                ) : null} */}

                                <li className="headCart">
                                    <Button
                                        className="respNavBtn"
                                        onClick={toggleDrawer('right', true)}
                                    >
                                        <span className="material-icons">menu</span>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </header>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch onClose={() => setState({ top: false })} />
                        </SwipeableDrawer>
                    </React.Fragment>
                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span class="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>
                                    {global.storeConfigration?.disable_buynow?.value !== '1' ? (
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/gallery" exact>
                                                <span class="material-icons">collections</span> Buy
                                                Now
                                            </NavLink>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/auctions" exact>
                                            <span class="material-icons">search</span> Auction
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/search" exact>
                                            <span class="material-icons">category</span> Products
                                        </NavLink>
                                    </ListItem>
                                    {global.storeConfigration?.external_schedule_pickup_link
                                        ?.value && (
                                        <ListItem button onClick={() => setModal(true)}>
                                            <span class="material-icons">local_shipping</span>{' '}
                                            Schedule Pickup
                                        </ListItem>
                                    )}

                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    Profile
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    Favorites
                                                </NavLink>
                                            </ListItem>
                                            {global?.pluginConfiguration?.buyers_assurance
                                                ?.enable == 1 && (
                                                <ListItem button>
                                                    <NavLink
                                                        to="/dashboard/subscription"
                                                        activeClassName="active"
                                                    >
                                                        <span className="material-icons">
                                                            store
                                                        </span>
                                                        Subscription
                                                    </NavLink>
                                                </ListItem>
                                            )}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/preference"
                                                >
                                                    <span class="material-icons">
                                                        notifications
                                                    </span>
                                                    Preference
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/aboutus">
                                            <span className="material-icons">groups</span>
                                            About Us
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/faq">
                                            <span className="material-icons">quiz</span>
                                            FAQs
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            <span className="material-icons">groups</span>
                                            How It Works
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">description</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>
                                    <Headernavmenu type="mobile" />

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    ) : (
                                        <ListItem button onClick={logoutUser}>
                                            <span className="material-icons">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItem>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                </>
            )}
            {global?.storeDetails?.text?.length > 0 ? (
                <div className="textSliderWrapper py-2">
                    <Swiper
                        navigation={false}
                        spaceBetween={13}
                        grabCursor={true}
                        slidesPerView={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={false}
                        modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {global?.storeDetails?.text?.map((val) => (
                            <SwiperSlide>
                                <div>
                                    <p className="mb-0">{val}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                ''
            )}
            {userCount.itemunPaidCount ? (
                <div className="auctionHasItems noPrint">
                    <img src="/assets/svg/TrophyIcon.svg" />
                    <h3>
                        Congratulations! You have won an auction.{' '}
                        <Link to={`/dashboard/mybids?s=1`}>Click here</Link> to make the payment.
                    </h3>
                </div>
            ) : null}
            <Popup
                open={openModal}
                size="md"
                handleClose={changeDialogStatus}
                modaltitle="Schedule Pickup"
            >
                <div className="schedulePickup">
                    <iframe
                        height={600}
                        src={global.storeConfigration?.external_schedule_pickup_link?.value}
                    ></iframe>
                </div>
            </Popup>
        </>
    )
}

export default React.memo(Header)
