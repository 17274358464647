/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import './profile.css'
import AuthContext from '../../Product/context/auth/authContext'
import Loader from '../../assets/loader'
import StripeCustomerContext from '../../Product/context/stripe/customer/customerContext'
import UserContext from '../../Product/context/user/userContext'
import { handleRedirectInternal, mapData, jsonToFormData } from '../../Product/common/components'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import axios from 'axios'
import { swapCountry } from '../../utils/commonFunctions'
import { Link, useHistory } from 'react-router-dom'
import Popup from '../../Product/components/organisms/Popup'
import AlertContext from '../../Product/context/alert/alertContext'
import Paypal from '../../utils/CommonFunctionality/Paypal'
import { useTranslation } from 'react-i18next'
import UserActivity from '../../utils/CommonFunctionality/UserActivity'

const ProfileFormComponent = (props) => {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const { setAlert } = alertContext
    const [btnLoading, setBtnLoading] = useState(false)
    const {
        createSellerCustomer,
        CheckSellerCustomer,
        addCustomerProfile,
        Addpayoutsource,
        paypalselleronboard,
        responseStatus: responseStatusStripeCustomer,
        clearResponse,
    } = useContext(StripeCustomerContext)
    const { user, loadUser, isAuthenticated } = authContext

    let { editProfile, changepwd, responseStatus, clearResponse: clearResponseUser } = userContext
    const [isOpen, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [regBtnLoading, setRegBtnLoading] = useState(false)
    const [stripeDisable, setStripeDisable] = useState(false)
    const [addBank, setAddBank] = useState(false)
    const history = useHistory()
    const params = new URLSearchParams(window.location.search.substring(1))
    const imageUploader = useRef(null)
    const uploadedImage = useRef(null)
    const validationPassword = Yup.object({
        isPasswordSelected: Yup.boolean(),
        current_password: Yup.string().required('Required!'),
        new_password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
        confirm_new_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords not match')
            .required('Required!'),
    })
    const formikChange = useFormik({
        initialValues: {
            isPasswordSelected: 1,
            current_password: '',
            new_password: '',
            confirm_new_password: '',
        },
        validationSchema: validationPassword,
        onSubmit: async (values) => {
            changepwd(values)
        },
    })

    const changePassword = [
        {
            label: t('current_password'),
            name: 'current_password',
            type: 'password',
            placeholder: 'Enter your current password',
            class: 'col-md-6 col-sm-12 thisInput',
            formik: formikChange,
        },
        {
            label: t('new_password'),
            name: 'new_password',
            type: 'password',
            placeholder: 'Enter your new password',
            class: 'col-md-6 col-sm-12 thisInput',
            formik: formikChange,
        },
        {
            label: t('confirm_password'),
            name: 'confirm_new_password',
            type: 'password',
            placeholder: 'Re enter your password',
            class: 'col-md-6 col-sm-12 thisInput',
            formik: formikChange,
        },
    ]

    // const phoneRegExp =
    //     /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    // const pinCodeRegExp = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
    // const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&\d]{8,}$/i
    const validationArray = Yup.object({
        first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required('Required'),
        last_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z'\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email format')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        phone:
            props.theme == 'artauction'
                ? Yup.string().trim().min(10, 'Invalid!').required('Required')
                : global?.storeConfigration?.ten_digit_phone_number?.value == 1
                ? Yup.string()
                      .trim()
                      .matches(/^[+]?[0-9]+$/, 'Only numbers are allowed')
                      .min(10, 'Invalid!')
                      .max(10, 'Invalid!')
                      .required('Required')
                : props.phone_country_code_must
                ? Yup.string()
                      .trim()
                      .matches(/^\+/, 'Enter country code at the start')
                      .matches(/^[+]?[-]?[^a-z][0-9]+$/, 'Only numbers are allowed')
                      .min(12, 'Invalid Number!')
                      .max(16, 'Maximum 14 characters')
                      .required('Required')
                : Yup.string()
                      .trim()
                      .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                      .min(10, 'Invalid!')
                      .max(17, 'Maximum 17 characters')
                      .required('Required'),
        country: Yup.string().trim().required('Required'),
        state: Yup.string().trim().required('Required'),
        address1: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters')
            .required('Required'),
        address2: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Special characters not allowed')
            .max(250, 'Maximum 250 characters'),
        city: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Only characters allowed')
            .required('Required'),
        zip: Yup.string()
            .when([], {
                is: () => formik?.values.country != 156,
                then: Yup.string().required('Required!'),
                otherwise: Yup.string().notRequired(),
            })
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, 'Special characters not allowed')
            .min(5, 'Minimum 5 characters')
            .max(7, 'Maximum 7 characters'),
        // buyer_document: Yup.mixed().test(
        //     'type',
        //     'Only the following formats are accepted:  pdf / doc / docx / jpeg / jpg / png ',
        //     (value) => {
        //         return (
        //             (value && value[0]?.type === 'image/jpeg') ||
        //             value[0]?.type === 'image/jpg' ||
        //             value[0]?.type === 'image/png' ||
        //             value[0]?.type === 'application/pdf' ||
        //             value[0]?.type === 'application/msword' ||
        //             value[0]?.type ===
        //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //         )
        //     },
        //),
        // card_profileid: Yup.string().when('role', {
        //     is: 1,
        //     then: Yup.string().trim().required('Required'),
        // }),
    })

    const bankvalidationarray = Yup.object({
        bank_account_holder_name: Yup.string().required('Required'),
        bank_account_holder_type: Yup.string().required('Required'),
        bank_routing_number: Yup.string().when('bank_country', {
            is: 'CA',
            then: Yup.string(),
            otherwise: Yup.string().required('Required'),
        }),
        bank_transit_number: Yup.string().when('bank_country', {
            is: 'CA',
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
        }),
        bank_institution_number: Yup.string().when('bank_country', {
            is: 'CA',
            then: Yup.string().required('Required'),
            otherwise: Yup.string(),
        }),
        bank_account_number: Yup.string().required('Required'),
    })
    useEffect(() => {
        if (user) {
            setLoading(false)
            formik.values.first_name = user.first_name || ''
            formik.values.last_name = user.last_name || ''
            formik.values.email = user.email || ''
            formik.values.phone = user.phone || ''
            formik.values.country = user.country || ''
            formik.values.state = user.state || ''
            formik.values.city = user.city || ''
            formik.values.address1 = user.address1 || ''
            formik.values.address2 = user.address2 || ''
            formik.values.zip = user.zip || ''
            formik.values.card_profileid = user.card_profileid || ''
            formik.values.company_name = user.company_name || ''
            formik.values.role = (user.role == 1 ? 1 : 0) || ''
            formik.values.user_profile_image = user.avatar || ''
            formik.values.aboutme = user.aboutme || ''
            formik.values.artist_type = user.user_type || ''
            formik.values.transfer_location = `${user.transfer_location}` || '0'
            formik.values.default_shipping = `${user.default_shipping}` || '0'
            formik.values.buyer_document_val = []
            formik.values.buyer_document_exist = user.taxfile_org.includes('buyer')
                ? [JSON.parse(user.taxfile_org).buyer]
                : []
            formik.values.seller_document_1 = []
            formik.values.seller_document_exist = user.taxfile_org.includes('seller_1')
                ? [JSON.parse(user.taxfile_org).seller_1]
                : []
        }
    }, [user])
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const handleimageLoader = (e) => {
        const [file] = e.target.files
        formik.setFieldValue('user_profile_image', file)
        if (file) {
            const reader = new FileReader()
            const { current } = uploadedImage
            current.file = file
            reader.onload = (e) => {
                current.src = e.target.result
            }
            reader.readAsDataURL(file)
        }
    }
    const linkstripe = () => {
        setStripeDisable(true)
        var data = {
            account_id: process.env.REACT_APP_AUCTIONPAYID,
            email: user.email,
            country: user.country == 38 ? 'CA' : 'US',
        }
        if (global.storeConfigration?.payment_interval?.value == 1) {
            data.interval = 'manual'
        }
        createSellerCustomer(data)
    }
    const checkstripe = () => {
        var data = {
            account_id: user.company_name,
            redirect_url: window.location.href,
        }
        localStorage.setItem('StripeCheck', true)
        CheckSellerCustomer(data)
    }
    // useEffect(() => {
    //     if (user) {
    //         if (user.card_paymentid != '' && user.card_profileid == '') {
    //             checkstripe()
    //         }
    //     }
    // }, [user])
    useEffect(() => {
        if (localStorage.getItem('StripeCheck')) {
            setStripeDisable(true)
            localStorage.removeItem('StripeCheck')
            const buttonEnable = setTimeout(() => {
                setStripeDisable(false)
            }, 126000)
            return () => clearTimeout(buttonEnable)
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            country: '',
            state: '',
            city: '',
            address1: '',
            address2: '',
            zip: '',
            role: 0,
            notification: '0',
            category_email: '0',
            card_profileid: '',
            company_name: '',
            buyer_document_val: [],
            buyer_document_exist: [],
            user_profile_image: '',
            user_profile_image: '',
            aboutme: '',
            auction_io: 1,
            seller_document_1: [],
            seller_document_exist: [],
            tempAddress: '',
            transfer_location: '1',
            default_shipping: '0',
            artist_type: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            var send_data = values
            send_data.buyer_document =
                values.buyer_document_val.length > 0
                    ? send_data.buyer_document_val[0]
                    : send_data.buyer_document_exist
            send_data.seller_document_1 =
                values.seller_document_1.length > 0
                    ? send_data.seller_document_1[0]
                    : send_data.seller_document_exist
            setRegBtnLoading(true)
            await editProfile(jsonToFormData(send_data))
        },
    })
    useEffect(() => {
        if (responseStatusStripeCustomer) {
            // console.log('++++++++++++=', responseStatusStripeCustomer)
            if (
                responseStatusStripeCustomer.from === 'sellercustomer' ||
                responseStatusStripeCustomer.from === 'checksellercustomer' ||
                responseStatusStripeCustomer.from === 'add_payout_source'
            ) {
                if (responseStatusStripeCustomer.status === 'success') {
                    if (responseStatusStripeCustomer.from === 'sellercustomer') {
                        // console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
                        formik.values.company_name = responseStatusStripeCustomer.data.id
                        //formik.handleSubmit()
                        var send_data = formik.values
                        editProfile(jsonToFormData(send_data))
                        setStripeDisable(false)
                        //console.log('ccccccccccccccccccccccccc', formik.values)
                    }

                    if (responseStatusStripeCustomer.from === 'checksellercustomer') {
                        if (responseStatusStripeCustomer.data.url) {
                            window.location.href = responseStatusStripeCustomer.data.url
                        } else if (responseStatusStripeCustomer.data == 'Payout not enabled!') {
                            localStorage.removeItem('StripeCheck')
                            setOpen(true)
                        } else {
                            formik.values.card_profileid = user.company_name
                            formik.values.company_name = ''
                            formik.values.role = 1
                            var send_data = formik.values
                            editProfile(jsonToFormData(send_data))
                            localStorage.removeItem('StripeCheck')
                            if (addBank) {
                                const redirectFunc = setTimeout(() => {
                                    if (props.theme == 'artauction') {
                                        if (params.get('moveto')) {
                                            // setAlert(
                                            //     'You can able to change product status to live now',
                                            //     'success',
                                            // )
                                            handleRedirectInternal(
                                                history,
                                                'dashboard/mylots?moveto="live"',
                                            )
                                        } else {
                                            handleRedirectInternal(
                                                history,
                                                'post-project?type=auction',
                                            )
                                        }
                                    } else {
                                        handleRedirectInternal(history, 'post-auction')
                                    }
                                }, 3000)
                                return () => clearTimeout(redirectFunc)
                            }
                        }
                    }
                    if (responseStatusStripeCustomer.from === 'add_payout_source') {
                        setOpen(false)
                        setAddBank(true)
                        checkstripe()
                    }
                } else {
                    // Alert()
                    setAlert(responseStatusStripeCustomer.message, 'error')
                }
            }
        }
        clearResponse()
    }, [responseStatusStripeCustomer])

    const bankformik = useFormik({
        initialValues: {
            account_id: user?.company_name ? user.company_name : '',
            bank_country: user?.country == 38 ? 'CA' : 'us',
            bank_currency: user?.country == 38 ? 'cad' : 'usd',
            bank_account_holder_name: '',
            bank_account_holder_type: '',
            bank_routing_number: '',
            bank_account_number: '',
            bank_transit_number: '',
            bank_institution_number: '',
            bank_token: true,
        },
        validationSchema: bankvalidationarray,
        onSubmit: (values) => {
            if (values.bank_country == 'CA') {
                values.bank_routing_number =
                    values.bank_transit_number + '-' + values.bank_institution_number
            }

            // return console.log(values, 'innnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
            values.account_id = user.company_name
            Addpayoutsource(values)
        },
    })
    useEffect(() => {
        if (user) {
            bankformik.setFieldValue('bank_country', user?.country == 38 ? 'CA' : 'us')
            bankformik.setFieldValue('bank_currency', user?.country == 38 ? 'cad' : 'usd')
            if (global.pluginConfiguration?.paypal_payout?.enable == 1) {
                user.paypal_mails = JSON.parse(
                    user.paypal_address.includes('[') ? user.paypal_address : '[]',
                )
                user.primary_paypal_id = user.paypal_mails.find((val) => val.primary)
            }
        }
    }, [user])
    // useEffect(() => {
    //     formik.setFieldValue('buyer_document_exist', [])
    // }, [formik.values.buyer_document])
    const registerInfo = {
        formik: formik,
        data: [
            {
                label: t('first_name') + '*',
                name: 'first_name',
                type: 'text',
                placeholder: 'Enter your first name',
                class: 'col-12 col-sm-6 thisInput',
                autoFocus: true,
            },
            {
                label: t('last_name') + '*',
                name: 'last_name',
                type: 'text',
                placeholder: 'Enter your last name',
                class: 'col-12 col-sm-6 thisInput',
            },
            {
                label: t('email_address') + '*',
                name: 'email',
                type: 'email',
                disabled: true,
                placeholder: 'Enter your email address',
                class: 'col-12 col-sm-6 thisInput',
            },
            {
                label: t('mobile_number') + '*',
                name: 'phone',
                type: props.theme == 'artauction' || props.mobile >= 1 ? 'phone' : 'text',
                placeholder: 'Enter your mobile number',
                class: 'col-12 col-sm-6 thisInput',
                countryCodeEditable: props.theme == 'artauction' ? true : false,
                enableLongNumbers: props.theme == 'artauction' || props.mobile == 1 ? 1 : 0,
            },
            {
                label: t('address_line_1') + '*',
                name: 'address1',
                type: global.storeConfigration?.map_input?.value == '1' ? 'mapInput' : 'text',
                placeholder: 'Enter your address line 1',
                class: 'col-12 col-sm-6 thisInput',
            },
            {
                label: t('address_line_2'),
                name: 'address2',
                type: 'text',
                placeholder: 'Enter your address line 2',
                class: 'col-12 col-sm-6 thisInput',
            },
        ],
    }
    if (
        global.storeConfigration?.disbled_country?.value == 0 ||
        global.storeConfigration?.disbled_country?.value == undefined
    ) {
        registerInfo.data.push({
            label: t('country') + '*',
            placeholder: 'Select your country',
            class: 'col-12 col-sm-6 thisInput',
            type: 'select',
            options: swapCountry(countryList).map((country) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = country.name
                busCategoryChanged.value = country.location_id
                return busCategoryChanged
            }),
            name: 'country',
        })
    }
    registerInfo.data.push(
        {
            label: user?.country == '38' ? 'Province' + '*' : t('state') + '*',
            placeholder: user?.country == '38' ? 'Select your province' : 'Select your state',
            class: stateList.length > 0 ? 'col-12 col-sm-6 thisInput' : 'd-none',
            type: 'select',
            options: stateList.map((buscat) => {
                let busCategoryChanged = {}
                busCategoryChanged.show = buscat.name
                busCategoryChanged.value = buscat.name
                return busCategoryChanged
            }),
            name: 'state',
        },
        {
            label: t('city') + '*',
            name: 'city',
            type: 'text',
            placeholder: 'Enter your city',
            class: 'col-12 col-sm-6 thisInput',
        },

        {
            label: user?.country == '38' ? 'Postal Code' + '*' : t('Zip_code') + '*',
            placeholder: user?.country == '38' ? 'Enter your postal code' : 'Enter your zip code',
            class: `col-12 thisInput col-sm-6 ${formik?.values?.country == 156 ? 'd-none' : ''}`,
            type: 'text',
            name: 'zip',
        },
        {
            label: t('art_type'),
            name: 'artist_type',
            type: 'text',
            placeholder: 'Enter your artist type',
            class: `col-12 col-sm-6 ${
                global.storeConfigration?.enable_bio_page?.value == 1 && formik.values.role == 1
                    ? ''
                    : 'd-none'
            }`,
        },
        {
            title: '',
            type: 'check',
            name: 'transfer_location',
            int: true,
            options: [
                {
                    id: '1',
                    description: `Transfer All ${global.storeConfigration?.transfer_city_location_from?.value} Orders To ${global.storeConfigration?.transfer_city_location?.value}`,
                },
            ],
            class:
                global.storeConfigration?.transfer_item_location?.value == '1'
                    ? 'col-sm-6'
                    : 'd-none',
        },
        {
            title: '',
            type: 'check',
            name: 'default_shipping',
            int: true,
            options: [
                {
                    id: '1',
                    description: `Please ship all items and charge card on file`,
                },
            ],
            class:
                global.storeConfigration?.default_shipping_option?.value == '1'
                    ? 'col-sm-6'
                    : 'd-none',
        },
        {
            type: 'uploadDropZone',
            name: 'seller_document_1',
            titleText: t('upload_banner_ima'),
            innerText: (
                <div className="text-center">
                    <span className="material-icons">add_photo_alternate</span>
                    <br />
                    <p className="mb-2"></p>
                    <span>{t('recommended_dimen')}: 1500px * 300px</span>
                </div>
            ),
            class: `col-12 col-sm-12 ${
                global.storeConfigration?.enable_bio_page?.value == 1 && formik.values.role == 1
                    ? ''
                    : 'd-none'
            }`,
            folder: 'product',
            multiple: false,
            compressImages: true,
            accept: 'image/gif,image/*',
        },
        {
            type: 'previousImages',
            formik: formik,
            name: 'seller_document_exist',
            class: `col-12 col-sm-12 ${
                global.storeConfigration?.enable_bio_page?.value == 1 && formik.values.role == 1
                    ? ''
                    : 'd-none'
            }`,
            folder: '',
        },
        {
            label: t('about_me'),
            name: 'aboutme',
            type: 'ckeditor',
            placeholder: '',
            class: `col-12 col-sm-12 ${
                global.storeConfigration?.enable_bio_page?.value == 1 && formik.values.role == 1
                    ? ''
                    : 'd-none'
            }`,
        },
    )
    const bank_details = {
        formik: bankformik,
        data: [
            {
                label: 'Holder Name',
                name: 'bank_account_holder_name',
                type: 'text',
                placeholder: 'Enter your bank holder name',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: 'Account Type',
                placeholder: 'Select your account type',
                class: 'col-12 col-sm-6',
                type: 'select',
                options: [
                    // {
                    //     show: 'Select Type',
                    //     value: '',
                    // },
                    {
                        show: 'Individual',
                        value: 'individual',
                    },
                    {
                        show: 'Company',
                        value: 'company',
                    },
                ],
                name: 'bank_account_holder_type',
            },
            {
                label: 'Routing Number',
                name: 'bank_routing_number',
                type: 'text',
                placeholder: 'Enter routing number',
                class: `col-12 col-sm-6 ${user?.country == '38' ? 'd-none' : ''}`,
                autoFocus: true,
            },
            {
                label: 'Transit Number',
                name: 'bank_transit_number',
                type: 'text',
                placeholder: 'Enter transit number',
                class: `col-12 col-sm-6 ${user?.country == '38' ? '' : 'd-none'}`,
                autoFocus: true,
            },
            {
                label: 'Institution Number',
                name: 'bank_institution_number',
                type: 'text',
                placeholder: 'Enter institution number',
                class: `col-12 col-sm-6 ${user?.country == '38' ? '' : 'd-none'}`,
                autoFocus: true,
            },
            {
                label: 'Account Number',
                name: 'bank_account_number',
                type: 'text',
                placeholder: 'Enter account number',
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
        ],
    }
    if (user) {
        if (user.role == 1) {
            // registerInfo.data.push({
            //     label: 'Account Id' + '*',
            //     placeholder: 'Enter your account id',
            //     class: 'col-12 col-sm-6',
            //     type: 'text',
            //     name: 'card_profileid',
            // })
        } else {
            if (global.storeConfigration?.disable_tax_exemption?.value != 1) {
                registerInfo.data.push(
                    {
                        type: 'uploadDropZone',
                        formik: formik,
                        name: 'buyer_document_val',
                        titleText: t('tax_certified'),
                        innerText: t('format') + ' pdf / doc / docx / jpeg / jpg / png',
                        class: 'col-12 thisInput',
                        folder: '',
                        multiple: false,
                        compressImages: false,
                        accept: 'image/jpeg, image/jpg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    },
                    {
                        type: 'previousImages',
                        formik: formik,
                        name: 'buyer_document_exist',
                        class: 'col-12 thisInput',
                        folder: '',
                    },
                )
            }
        }
    }

    const getLocationData = () => {
        var body = {
            countryCode: formik.values.country,
        }
        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setStateList(response.data.state)
                    setCountryList(response.data.country)
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        getLocationData()
    }, [formik.values.country])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.status === 'success') {
                if (responseStatus.from === 'editProfile') {
                    clearResponseUser()
                    loadUser()
                    setRegBtnLoading(false)
                }
                if (responseStatus.from === 'changepwd') {
                    formikChange.resetForm()
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        setTimeout(() => {
            // console.log('scroll to function inititated')
            window.scrollTo(0, 0)
        }, 1000)
    }, [])

    const setstatefrommap = () => {
        if (formik.values.tempAddress.split(',').length > 3) {
            let newAdd = formik.values.tempAddress.split(',')
            let addLength = newAdd.length

            let findValue = newAdd[addLength - 2].trim()
            let newState = stateList.findIndex(
                (e) => e.name == findValue || e.map_code_io == findValue,
            )
            if (newState != -1) {
                formik.setFieldValue('state', stateList[newState].name)
            } else {
                formik.setFieldValue('state', '')
            }
            // console.log('newAdd state', newAdd[addLength - 2], newState)
            let findcity = newAdd[addLength - 3].trim()
            formik.setFieldValue('city', findcity)
        } else {
            formik.setFieldValue('state', '')
            formik.setFieldValue('city', '')
        }
    }

    useEffect(() => {
        if (formik.values.tempAddress != '' && global.storeConfigration?.map_input?.value == '1') {
            if (formik.values.tempAddress.split(',').length > 3) {
                formik.values.address1 = formik.values.tempAddress
                let newAdd = formik.values.tempAddress.split(',')
                let addLength = newAdd.length

                let findValue =
                    newAdd[addLength - 1].trim() == 'USA'
                        ? 'United States'
                        : newAdd[addLength - 1].trim() == 'UK'
                        ? 'United Kingdom'
                        : newAdd[addLength - 1].trim()

                if (countryList.length > 0) {
                    let newCountry = countryList.findIndex((e) => e.name == findValue)
                    // console.log('newAdd', newAdd, addLength, newCountry, countryList)
                    if (newCountry > 0) {
                        if (formik.values.country != countryList[newCountry].location_id) {
                            formik.setFieldValue('country', countryList[newCountry].location_id)
                        } else {
                            setstatefrommap()
                        }
                    } else {
                        formik.setFieldValue('country', '')
                    }
                }
            }
        }
    }, [formik.values.tempAddress])

    useEffect(() => {
        if (formik.values.tempAddress != '' && global.storeConfigration?.map_input?.value == '1') {
            setstatefrommap()
        }
    }, [stateList])

    // useEffect(() => {
    //     if (props.paypal_suc) {
    //         handleRedirectInternal(history, 'dashboard/mylots?moveto="live"')
    //     }
    // }, [props])

    useEffect(() => {
        if (responseStatusStripeCustomer?.from === 'paypal_seller_onboard') {
            if (responseStatusStripeCustomer?.status === 'success') {
                var response_data = responseStatusStripeCustomer?.data
                if (response_data?.redirect_url?.href) {
                    window.location.href = response_data.redirect_url.href
                }
            }
        }
    }, [responseStatusStripeCustomer])

    return (
        <>
            <UserActivity page="profile" />
            <div className="container">
                {loading ? (
                    <div className="text-center">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <div className="strpStup mw-900 mx-auto mb-5">
                            {global.pluginConfiguration?.flutterwave_payment_gateway?.enable ==
                            1 ? (
                                <>
                                    {user?.card_profileid == '' ? (
                                        <div className="accChild">
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <div className="">
                                                    <h4 className="mp-head colorPrimary">
                                                        Flutterwave Account SetUp
                                                    </h4>
                                                    <p>
                                                        Setup your Flutterwave account to complete
                                                        seller registration
                                                    </p>
                                                </div>
                                                <PrimaryButton
                                                    type="submit"
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'flutteronboard',
                                                        )
                                                    }
                                                    label={'Click Here To Proceed Further'}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-100">
                                            <div className="">
                                                <h4 className="mp-head colorPrimary">
                                                    Flutterwave Account Info
                                                </h4>
                                            </div>
                                            <p className="stripeInfo active">
                                                <span className="material-icons">check_circle</span>
                                                You are successfully connected with Flutterwave.
                                                Your account id is {user?.card_profileid}.
                                            </p>
                                        </div>
                                    )}
                                </>
                            ) : global.pluginConfiguration?.paypal_payout?.enable == 1 ? (
                                <>
                                    {user?.card_profileid == '' || user?.verify_paypal == 0 ? (
                                        <>
                                            {props.seller_only_paypal && user?.role != 1 ? (
                                                ''
                                            ) : (
                                                <>
                                                    <div className="w-100">
                                                        <div className="">
                                                            <h4 className="mp-head colorPrimary">
                                                                PayPal Account Setup
                                                            </h4>
                                                        </div>
                                                        <p className="stripeInfo inactive">
                                                            Login with paypal to receive payout.
                                                        </p>
                                                    </div>
                                                    <Paypal redirect_url={props.redirect_url} />
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <div className="w-100">
                                            <div className="">
                                                <h4 className="mp-head colorPrimary">
                                                    PayPal Account Info
                                                </h4>
                                            </div>
                                            <p className="stripeInfo active">
                                                <span className="material-icons">check_circle</span>
                                                You are successfully connected with paypal. Your
                                                primary mail id is {
                                                    user?.primary_paypal_id?.value
                                                }{' '}
                                                and it is{' '}
                                                {user?.verify_paypal == 1
                                                    ? 'verified'
                                                    : 'unverified'}
                                                .
                                            </p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {(!props.buyerOnly && formik.values.role == 1) ||
                                    props.showAccount ? (
                                        <div className="accChild">
                                            {global.storeConfigration?.stripe_integration?.value ==
                                                1 && user?.card_profileid == '' ? (
                                                <div className="d-flex align-items-center justify-content-between w-100">
                                                    <div className="">
                                                        <h4 className="mp-head colorPrimary">
                                                            Stripe Account SetUp
                                                        </h4>
                                                        <p>
                                                            Setup your stripe account to complete
                                                            seller registration
                                                        </p>
                                                    </div>
                                                    <PrimaryButton
                                                        type="submit"
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                'onboard',
                                                            )
                                                        }
                                                        label={'Click Here To Proceed Further'}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="w-100">
                                                        <div className="">
                                                            <h4 className="mp-head colorPrimary">
                                                                {user?.company_name == '' &&
                                                                user?.card_profileid == ''
                                                                    ? 'Stripe Account SetUp'
                                                                    : t('stripe_info')}
                                                            </h4>
                                                        </div>
                                                        {user?.company_name == '' &&
                                                        user?.card_profileid == '' ? (
                                                            <p className="stripeInfo inactive">
                                                                Set up Stripe Pay to receive payout.
                                                            </p>
                                                        ) : user?.card_profileid == '' ? (
                                                            <>
                                                                {stripeDisable ? (
                                                                    <p className="stripeInfo partial">
                                                                        Stripe is processing your
                                                                        data... This may take upto 2
                                                                        mins
                                                                    </p>
                                                                ) : (
                                                                    <p className="stripeInfo partial">
                                                                        Your Stripe Pay account ID
                                                                        is: {user.company_name}.
                                                                        Please continue Stripe Pay
                                                                        setup process.
                                                                    </p>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <p className="stripeInfo active">
                                                                <span className="material-icons">
                                                                    check_circle
                                                                </span>
                                                                {t('stripe_id') + ':'}{' '}
                                                                {user?.card_profileid}.
                                                            </p>
                                                        )}
                                                    </div>

                                                    <>
                                                        {user?.company_name == '' &&
                                                        user?.card_profileid == '' ? (
                                                            <div className="col-12 col-sm-3 pb12">
                                                                <PrimaryButton
                                                                    type="submit"
                                                                    onClick={(e) => linkstripe()}
                                                                    label={'LINK'}
                                                                    disabled={stripeDisable}
                                                                />
                                                            </div>
                                                        ) : user?.card_profileid == '' ? (
                                                            <div className="col-12 col-sm-3 pb12">
                                                                {/* <p>
                                        "Your Stripe Pay account ID is: {user.card_paymentid}.
                                        Please continue Stripe Pay setup process."
                                    </p> */}
                                                                <PrimaryButton
                                                                    type="submit"
                                                                    onClick={(e) => checkstripe()}
                                                                    label={'SETUP'}
                                                                    disabled={stripeDisable}
                                                                />
                                                            </div>
                                                        ) : (
                                                            ''
                                                            // <p>
                                                            //     "You are linked to Stripe Pay now. Your account ID is:{' '}
                                                            //     {user.card_profileid}."
                                                            // </p>
                                                        )}
                                                    </>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )}
                            {global.pluginConfiguration?.paypal_partner?.enable == 1 ? (
                                <>
                                    {(!props.buyerOnly && formik.values.role == 1) ||
                                    props.showAccount ? (
                                        <>
                                            {user?.paypal_merchant_id == '' ? (
                                                <div className="accChild">
                                                    <div className="d-flex align-items-center justify-content-between w-100">
                                                        <div className="">
                                                            <h4 className="mp-head colorPrimary">
                                                                PayPal Merchant Account
                                                            </h4>
                                                            <p>
                                                                Setup your PayPal account to
                                                                complete seller registration
                                                            </p>
                                                        </div>
                                                        <PrimaryButton
                                                            type="submit"
                                                            onClick={() =>
                                                                paypalselleronboard({
                                                                    reseller: user?.id
                                                                        ? user.id
                                                                        : 0,
                                                                    redirect_url:
                                                                        window.location.href +
                                                                        '/paypalonboardsuccess',
                                                                })
                                                            }
                                                            label={'Click Here To Proceed Further'}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-100">
                                                    <div className="">
                                                        <h4 className="mp-head colorPrimary">
                                                            PayPal Account Info
                                                        </h4>
                                                    </div>
                                                    <p className="stripeInfo active">
                                                        <span className="material-icons">
                                                            check_circle
                                                        </span>
                                                        You are successfully connected with Paypal.
                                                        Your account id is{' '}
                                                        {user?.paypal_merchant_id}.
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="custom-container-box Extra profile-form-wrapper">
                            <div className="mw-900 mx-auto">
                                <h2 className="mp-head mb-4">
                                    {t('my_profile')}{' '}
                                    {user?.tax_excempt ? (
                                        <small>
                                            <span className="material-icons-outlined">
                                                verified
                                            </span>
                                        </small>
                                    ) : null}
                                    {user?.buyer_subscribed ? (
                                        <small>
                                            <span className="material-icons-outlined">
                                                verified_user
                                            </span>
                                        </small>
                                    ) : null}
                                </h2>{' '}
                            </div>

                            <div className="form-details mw-900 profile-form-wrap mx-auto myProfile">
                                {global.storeConfigration?.enable_bio_page?.value == 1 &&
                                formik.values.role == 1 ? (
                                    <>
                                        <div className="ppic-wrap">
                                            <img
                                                className="pp-img"
                                                ref={uploadedImage}
                                                src={
                                                    formik.values.user_profile_image
                                                        ? `${process.env.REACT_APP_BASE_URL}uploads/${formik.values.user_profile_image}`
                                                        : `https://ui-avatars.com/api/?name=${
                                                              formik.values.first_name?.split(
                                                                  ' ',
                                                              )[0]
                                                          }+${
                                                              formik.values.last_name?.split(' ')[0]
                                                          }`
                                                }
                                                alt="profile.jpg"
                                                onError={(e) =>
                                                    (e.target.src = `https://ui-avatars.com/api/?name=${
                                                        formik.values.first_name?.split(' ')[0]
                                                    }+${formik.values.last_name?.split(' ')[0]}`)
                                                }
                                            />
                                            <label
                                                for="profileImage"
                                                className="pp-edit material-icons"
                                            >
                                                edit
                                            </label>
                                            <input
                                                id="profileImage"
                                                type="file"
                                                name="profile_image"
                                                onChange={handleimageLoader}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                ref={imageUploader}
                                            />
                                        </div>
                                        <PrimaryButton
                                            btnSize="vwBioBtn"
                                            type="button"
                                            onClick={() =>
                                                window.open(
                                                    window.location.href.includes(
                                                        global?.storeDetails?.id,
                                                    )
                                                        ? `/${global?.storeDetails?.id}/bio/${user?.id}`
                                                        : `/bio/${user?.id}`,
                                                )
                                            }
                                            label={t('bio_page_view')}
                                        />
                                    </>
                                ) : null}
                                <form className="reg-step1" onSubmit={formik.handleSubmit}>
                                    <div className="row">{mapData(registerInfo)}</div>
                                    {/* {console.log('register data', registerInfo)} */}
                                    <div className="text-center form-group">
                                        <PrimaryButton
                                            type="submit"
                                            label={regBtnLoading ? 'Loading...' : t('submit')}
                                            disabled={regBtnLoading}
                                        />
                                    </div>
                                </form>
                                <form onSubmit={formikChange.handleSubmit} autoComplete="nofill">
                                    <div className="accChild">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="mp-head">{t('change_password')}</h4>
                                        </div>
                                        <div className="row">
                                            {Object.values(mapData(changePassword))}
                                        </div>
                                    </div>
                                    <PrimaryButton type="submit" label={t('submit')} />
                                </form>
                            </div>
                        </div>
                        <Popup
                            open={isOpen}
                            handleClose={(e) => setOpen(false)}
                            size="md"
                            className="save-search-modal"
                            modaltitle="Bank Details"
                        >
                            <>
                                <div className="row">{mapData(bank_details)}</div>

                                <div className="actionWrapper">
                                    <div className="row">
                                        <div className="col-sm-3 col-12"></div>
                                        <div className="col-md-6">
                                            <PrimaryButton
                                                onClick={(e) => bankformik.handleSubmit(e)}
                                                type="button"
                                                disabled={btnLoading}
                                                label={btnLoading ? 'Loading...' : 'ADD'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Popup>
                    </>
                )}
            </div>
        </>
    )
}

export default ProfileFormComponent
