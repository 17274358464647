import React, { useState, useContext, useEffect } from 'react'
import ProductContext from '../../../context/product/productContext'
import AuthContext from '../../../context/auth/authContext'

import './FavoriteCheckbox.css'

const FavoriteCheckbox = ({
    watchlisted,
    project_id,
    fill,
    data,
    from,
    getAllProducts,
    viewProduct,
    setViewProduct,
    setAddFav,
}) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated, loadUser } = authContext

    const [checked, setChecked] = useState(
        typeof watchlisted === 'boolean' ? watchlisted : Number(watchlisted) > 0 ? true : false,
    )
    const { addWatchlist, removeWatchlist } = productContext

    useEffect(() => {
        setChecked(
            typeof watchlisted === 'boolean' ? watchlisted : Number(watchlisted) > 0 ? true : false,
        )
        // return () => {
        //     setChecked(false)
        // }
    }, [project_id, watchlisted])
    useEffect(() => {
        if (typeof setAddFav !== 'undefined') {
            setAddFav(checked ? 1 : 0)
        }
    }, [checked])
    const toggleFavourite = (e) => {
        if (!checked) {
            if (window.storeDetails.theme === 5) {
                addWatchlist({ product_id: project_id, watchlistemail: '1' })
                    .then((res) => {
                        loadUser()
                        if (res.status === 'yes') {
                            if (typeof getAllProducts !== 'undefined') {
                                // console.log(viewProduct, 'viewProduct')
                                setChecked(true)
                                getAllProducts()
                            } else if (typeof viewProduct !== 'undefined') {
                                setCheckValue(project_id, 'add')
                            }
                        }
                    })
                    .catch((err) => {
                        // console.log(err, 'addwatchlist err')
                    })
            } else {
                addWatchlist({ product_id: project_id, watchlistemail: '1' })
                    .then((res) => {
                        if (res.status === 'yes') {
                            if (typeof getAllProducts !== 'undefined') {
                                // console.log(viewProduct, 'viewProduct')
                                setChecked(true)
                                getAllProducts()
                            } else if (typeof viewProduct !== 'undefined') {
                                setCheckValue(project_id, 'add')
                            }
                        }
                    })
                    .catch((err) => {
                        // console.log(err, 'addwatchlist err')
                    })
            }
        } else {
            removeWatchlist({ product_id: project_id })
                .then((res) => {
                    // console.log(res, 'ressssssssssssssssssssssss')
                    loadUser()
                    if (res.status === 'yes') {
                        setCheckValue(project_id)
                        if (typeof getAllProducts !== 'undefined') {
                            setChecked(false)
                            // console.log('aaaaaaaaaaaaaaaaaaaa')
                            getAllProducts()
                        } else if (typeof viewProduct !== 'undefined') {
                            setCheckValue(project_id, 'remove')
                        }
                    }
                })
                .catch((err) => {
                    // console.log(err, 'remove watchlist err')
                })
        }
    }

    const setCheckValue = (projectId, action) => {
        let productList = viewProduct
        let productIndex = productList?.findIndex((ele) => ele.id === projectId)
        if (action === 'add') {
            setChecked(true)
            productList[productIndex].watchlisted = 1
        } else if (action === 'remove') {
            setChecked(false)
            productList[productIndex].watchlisted = 0
        }
        if (typeof setViewProduct !== 'undefined') {
            setViewProduct(productList)
        }
    }
    // console.log('check', checked)

    return (
        <>
            {isAuthenticated && (
                <div
                    className={`${
                        global.storeDetails.id === 41 ? 'fullbasket-fav' : ''
                    } favoriteCheck d-flex justify-content-center align-items-center`}
                    onClick={() => from !== 'card' && setChecked(!checked)}
                >
                    <input
                        id={project_id}
                        type="checkbox"
                        checked={checked}
                        onChange={toggleFavourite}
                    />

                    {checked ? (
                        <label htmlFor={project_id}>
                            <span className="material-icons">favorite</span>
                        </label>
                    ) : (
                        <label htmlFor={project_id}>
                            {fill ? (
                                <span className="material-icons">favorite</span>
                            ) : (
                                <span className="material-icons">favorite_border</span>
                            )}
                        </label>
                    )}
                </div>
            )}
        </>
    )
}
export default FavoriteCheckbox
