import React, { useState, useEffect, useContext } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { handleRedirectInternal, mapData } from '../../Product/common/components'
import AuthContext from '../../Product/context/auth/authContext'
import Spinner from '../../Component/Spinner'
import { useTranslation } from 'react-i18next'
function LoginFunction({ title, subTitle, after_login_redirect_path, autoComplete }) {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { login_email } = useParams()
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    let searchParams = new URLSearchParams(window.location.search)
    let { login, responseStatus } = authContext

    let [passwordShown, setPasswordShown] = useState(false)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const validationArray = Yup.object({
        username: Yup.string()
            .email(t('invalid_email'))
            .required(t('required') + '!'),
        password: Yup.string().required(t('required') + '!'),
    })

    useEffect(() => {
        //console.log('login_email', login_email)
        if (
            window.location.pathname.includes('/autologin') ||
            window.location.pathname.includes('/auto-login')
        ) {
            setLoad(true)
            let username = login_email
            let password = '12345678'
            let autologin = 1
            let encrypt = window.location.pathname.includes('/auto-login') ? 1 : 0
            let loginValues = {
                username: username,
                password: password,
                autologin: autologin,
                encrypt: encrypt,
            }
            //console.log('loginvalues', loginValues)
            login(loginValues)
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            username: localStorage.username ? localStorage.username : '',
            password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
            community: 'auction_io',
            is_auctionio: 1,
            account_id:
                global.storeConfigration?.enable_payment_gateway_register?.value == 1
                    ? process.env.REACT_APP_AUCTIONPAYID
                    : undefined,
            description:
                global.storeConfigration?.enable_payment_gateway_register?.value == 1
                    ? 'Login Customer Creation'
                    : undefined,
            register_to_payment_gateway:
                global.storeConfigration?.enable_payment_gateway_register?.value,
            login_with_suspend: global.storeConfigration?.suspended_user_access?.value
                ? global.storeConfigration.suspended_user_access.value
                : 0,
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setLoading(true)
            if (values.remember_me) {
                localStorage.username = values.username
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.username
                delete localStorage.password
                delete localStorage.remember_me
            }
            login(values)
        },
    })

    const rememberMe = {
        formik: formik,
        data: [
            {
                label: t('rember_me'),
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'Remember me',
                class: 'w-100',
                formik: formik,
            },
        ],
    }

    const loginInfo = {
        formik: formik,
        data: [
            {
                label: t('email'),
                name: 'username',
                type: 'email',
                placeholder: t('enter_email'),
                class: 'col-12',
                autoFocus: true,
                autoComplete: autoComplete ? 1 : 0,
            },
            {
                label: t('password'),
                name: 'password',
                type: passwordShown ? 'text' : 'password',
                placeholder: t('enter_password'),
                class: 'col-12',
                autoComplete: autoComplete ? 1 : 0,
                endAdornment: passwordShown ? (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility_off
                    </span>
                ) : (
                    <span
                        className="material-icons cursorPointer"
                        onClick={togglePasswordVisiblity}
                    >
                        visibility
                    </span>
                ),
            },
        ],
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(
                        history,
                        after_login_redirect_path
                            ? after_login_redirect_path
                            : global.storeConfigration?.redirect_login?.value
                            ? global.storeConfigration.redirect_login.value
                            : searchParams.get('auction_id')
                            ? 'search/product-buyer-auction/' +
                              searchParams.get('auction_id') +
                              '?id_new=' +
                              searchParams.get('auction_id')
                            : 'search',
                    )
                    setLoad(false)
                }
                setLoading(false)
            }
        }
    }, [responseStatus])
    return (
        <>
            {load && <Spinner />}
            <form className="login-ip-box" onSubmit={formik.handleSubmit}>
                <h1>{title ? title : 'Sign In'}</h1>
                {subTitle && <h4>{subTitle}</h4>}
                <div className="row">{mapData(loginInfo)}</div>
                <div className="d-flex justify-content-between align-items-center fpRm mb-2">
                    {mapData(rememberMe)}
                    <div className="w-100 text-right forgottext">
                        <Link to="/forgot_password">{t('forgot_password')}?</Link>
                    </div>
                </div>
                <PrimaryButton disabled={loading} label={t('login')} type="submit" />
                <div className="newAcc mt-3 text-center">
                    <p>
                        Don&apos;t have an account? <Link to="/sign-up">Create Account</Link>
                    </p>
                </div>
            </form>
        </>
    )
}

export default LoginFunction
