import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import AllProducts from '../../../Component/MyBids/AllProducts'
import { useLocation, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../Product/common/components'
import { commonPaths } from '../../commonFunctions'
import StripeCustomerContext from '../../../Product/context/stripe/customer/customerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import UserActivity from '../UserActivity'
import buyerContext from '../../../Product/context/buyer/buyerContext'
const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            className="customTabsInner dashboard-tabs"
            // component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `buyer-dashboard-${index}`,
        'aria-controls': `buyer-dashboard-tabpanel-${index}`,
    }
}

const MyBidsPanel = (props) => {
    const history = useHistory()
    const search = useLocation().search
    const params = new URLSearchParams(search)
    const [value, setValue] = useState(
        global.storeConfigration?.disabled_auc_mulsel2?.value == 1
            ? 3
            : params.get('s')
            ? parseInt(params.get('s'))
            : 0,
    )
    const { t } = useTranslation()
    const { clearBuyerState } = useContext(buyerContext)

    const handleChange = (event, newValue) => {
        clearBuyerState()
        handleRedirectInternal(history, `${commonPaths.mybids.substring(1)}?s=${newValue}`)
        // setValue(newValue)
    }
    /*eslint-disable*/
    useEffect(() => {
        setValue(params.get('s') ? parseInt(params.get('s')) : 0)
    }, [window.location.search])
    //console.log('store', global.storeConfigration?.disable_buynow?.value)

    const authContext = useContext(AuthContext)

    const { user, loadUser, isAuthenticated } = authContext

    const {
        createStripeCustomer,
        addCustomerProfile,
        responseStatus: responseStatusStripeCustomer,
    } = useContext(StripeCustomerContext)

    useEffect(() => {
        if (
            user &&
            !user.card_paymentid &&
            global.storeConfigration?.credit_card_no_need?.value == 1
        ) {
            createStripeCustomer({
                account_id: process.env.REACT_APP_AUCTIONPAYID,
                description: 'TEST CUSTOMER',
                email: user.email,
                name: user.first_name + ' ' + user.last_name,
            })
        }
    }, [user])

    useEffect(() => {
        if (
            responseStatusStripeCustomer &&
            global.storeConfigration?.credit_card_no_need?.value == 1
        ) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from === 'create') {
                    //console.log('responseStatusStripeCustomer', responseStatusStripeCustomer)
                    addCustomerProfile({
                        customer_id: responseStatusStripeCustomer.data.id,
                    })
                }
                loadUser()
                // setIsLoading(false)
            }
        }
    }, [responseStatusStripeCustomer])

    return (
        <div>
            {global.storeConfigration?.disabled_auc_mulsel2?.value != 1 ? (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="buyer-dashboard"
                        className="mt-4 liquidationDashTabs"
                    >
                        <Tab label={t('bids_active')} {...a11yProps(0)} />
                        <Tab label={t('bids_won')} {...a11yProps(1)} />
                        <Tab label={t('bids_lost')} {...a11yProps(2)} />

                        {global.storeConfigration?.disable_buynow?.value !== '1' &&
                        global.storeConfigration?.need_post_buynow?.value != 0 ? (
                            <Tab label={t('buy_now')} {...a11yProps(3)} />
                        ) : null}
                        {global.pluginConfiguration?.make_an_offer?.enable ? (
                            <Tab label={'Make An Offer'} {...a11yProps(4)} />
                        ) : null}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <UserActivity page="dashboard_bid_active" />
                        <AllProducts
                            page="bidactive"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            enableRetractBid={props.enableRetractBid}
                            storeTheme={props.storeTheme}
                            noView={props.noView}
                            noProxy={props.noProxy}
                            redirect_product_view={props.redirect_product_view}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserActivity page="dashboard_bid_won" />
                        <AllProducts
                            page="bidwon"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            checkout_but_pos={props.checkout_but_pos}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <UserActivity page="dashboard_bid_lost" />
                        <AllProducts
                            page="bidlost"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <UserActivity page="dashboard_buynow" />
                        <AllProducts
                            page="buynow"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <UserActivity page="make_an_offer_page" />
                        <AllProducts
                            page="make_an_offer"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                            checkout_but_pos={props.checkout_but_pos}
                        />
                    </TabPanel>
                </>
            ) : (
                <>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="buyer-dashboard"
                        className="mt-4 liquidationDashTabs"
                    >
                        {global.storeConfigration?.disable_buynow?.value !== '1' &&
                        global.storeConfigration?.need_post_buynow?.value != 0 ? (
                            <Tab label="Buy Now" {...a11yProps(3)} />
                        ) : null}
                        {global.pluginConfiguration?.make_an_offer?.enable ? (
                            <Tab label={'Make An Offer'} {...a11yProps(4)} />
                        ) : null}
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <UserActivity page="dashboard_buynow" />
                        <AllProducts
                            page="buynow"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserActivity page="make_an_offer_page" />
                        <AllProducts
                            page="make_an_offer"
                            currency={props.currency}
                            sliderTheme={props.sliderTheme}
                            noTimer={props.noTimer}
                            shippingauction={props.shippingauction}
                            storeTheme={props.storeTheme}
                            redirect_product_view={props.redirect_product_view}
                            pickupCode={props.pickupCode}
                        />
                    </TabPanel>
                </>
            )}
        </div>
    )
}

export default MyBidsPanel
