import React, { useReducer } from 'react'
import PluginContext from './pluginContext'
import PluginReducer from './pluginReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import {
    SUBSCRIPTION,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_ALL_MESSAGES,
    GET_ALL_REFERRALS,
    GET_FEEDBACK_FIELDS,
    GET_ALL_FEEDBACK,
    GET_PRO_FEEDBACK,
    GET_ALL_BANKS,
} from './pluginTypes'

const ProductState = (props) => {
    const initialState = {
        responseStatus: null,
        all_messages: [],
        all_referrals: [],
        total_earned: 0,
        feedback_fields: [],
        all_feedback: [],
        single_feedback: {},
        all_banks: [],
    }

    const [state, dispatch] = useReducer(PluginReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const subscribeReseller = async (formData) => {
        const from = 'subscribeReseller'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'reseller-subscription',
                    formData,
                    '',
                    'plugin/subscription/site/subscription',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Subscribed successfully',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const subscribeBuyer = async (formData) => {
        const from = 'subscribeBuyer'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'buyer-assurance', formData, '', 'plugin/buyer/site/assurance'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Subscribed successfully',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const paypalLogin = async (formData) => {
        const from = 'paypalLogin'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'paypal-login', formData, '', 'plugin/paypal_payout/site/paypal'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const requestShipping = async (formData) => {
        const from = 'requestShipping'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'request-price',
                    formData,
                    '',
                    'plugin/messages/communication/connect',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Shipping Fee Requested Successfully!',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const requestTrackingNumber = async (formData) => {
        const from = 'requestTrackingNumber'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'requestTracking',
                    formData,
                    '',
                    'plugin/shipstation/service/getRate',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Tracking Number Requested Successfully!',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const donar_registration = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'add_new_donar', formData, '', 'plugin/charity/donation/donor'),
            ])

            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, 'donar_register')
            } else if (res.data.status === 'error') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, 'donar_register')
            }
        } catch (err) {
            resp.commonErrorResponse('donar_register')
        }
    }

    const getdonarexistingcard = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getusercard', formData, '', 'plugin/charity/donation/donor'),
            ])
            const from = 'donorcarddetails_bidderdetails_page'
            //console.log('response from bidderdetails', res)
            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getdonarlist = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'view_donar', formData, '', 'plugin/charity/donation/donor'),
            ])
            const from = formData?.from ? formData.from : 'donorlist_bidderdetails_page'
            //console.log('response from bidderdetails', res)
            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const composeMessage = async (formData) => {
        try {
            const from = 'composeMessage'
            const [res] = await Promise.all([
                apiCall('post', 'compose', formData, '', 'plugin/messages/communication/connect'),
            ])
            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllMessages = async (formData) => {
        try {
            const from = 'getAllMessages'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'viewmessages',
                    formData,
                    '',
                    'plugin/messages/communication/connect',
                ),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_MESSAGES,
                    payload: res.data.data.message.messages,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const get_Stage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getstage', formData, '', 'plugin/ticketing/seat/order'),
            ])
            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, 'Ticket_System')
            } else if (res.data.status === 'error') {
                res.data.response = res.data.data.message
                resp.commonResponse(res.data, 'Ticket_System')
            }
        } catch (err) {
            resp.commonErrorResponse('Ticket_System')
        }
    }

    const check_ticket_avialable = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'availableSeat', formData, '', 'plugin/ticketing/seat/order'),
            ])
            const from = formData.from ? formData.from : 'check_ticket_status'

            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                res.data.response = res.data.data.message
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const book_tickets = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createTicket', formData, '', 'plugin/ticketing/seat/order'),
            ])

            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, 'book_ticket')
            } else if (res.data.status === 'error') {
                res.data.response = res.data.data.message
                resp.commonResponse(res.data, 'booke_ticket')
            }
        } catch (err) {
            resp.commonErrorResponse('book_ticket')
        }
    }

    const get_booked_tickets = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'mytickets', formData, '', 'plugin/ticketing/seat/order'),
            ])

            if (res.data.status === 'success') {
                res.data.message = res.data.data.message
                resp.commonResponse(res.data, 'booked_ticket_status')
            } else if (res.data.status === 'error') {
                res.data.response = res.data.data.message
                resp.commonResponse(res.data, 'booked_ticket_status')
            }
        } catch (err) {
            resp.commonErrorResponse('booked_ticket_status')
        }
    }

    const requestdiscount = async (formData, cancelToken) => {
        const from = 'requestDiscount'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'applycouponsNew', formData, '', '', cancelToken),
            ])
            if (res.data.result === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.result }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getPlanDetails = async (formData) => {
        const from = 'get_plan_details'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get_plans', formData, '', 'plugin/subscription/site/subscription'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const disputeMessage = async (formData) => {
        const from = 'disputeMessage'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'compose', formData, '', 'plugin/dispute/communication/connect'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const requestRefund = async (formData) => {
        const from = 'requestRefund'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'requestRefund',
                    formData,
                    '',
                    'plugin/payment_geteway/stripe/refund',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getReferralList = async (formData) => {
        const from = 'getReferralList'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get-referral', formData, '', 'plugin/referral/site/referral'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_REFERRALS,
                    payload: res.data.data.result,
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const createReferral = async (formData) => {
        const from = 'createReferral'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'create-referral', formData, '', 'plugin/referral/site/referral'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllFeedback = async (formData) => {
        const from = 'getAllFeedback'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getallFeedback', formData, '', 'plugin/feedback/details/connect'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
                dispatch({
                    type: GET_ALL_FEEDBACK,
                    payload: res.data.data.message.messages,
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const addFeedback = async (formData) => {
        const from = 'addFeedback'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addFeedback', formData, '', 'plugin/feedback/details/connect'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getFeedbackField = async (formData) => {
        const from = 'getFeedbackField'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'getFeedbackField',
                    formData,
                    '',
                    'plugin/feedback/details/connect',
                ),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_FEEDBACK_FIELDS,
                    payload: res.data.data.message.messages,
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getproFeedback = async (formData) => {
        const from = 'getproFeedback'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getproFeedback', formData, '', 'plugin/feedback/details/connect'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_PRO_FEEDBACK,
                    payload: res.data.data.message?.messages[0],
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getAllBanks = async (formData) => {
        const from = 'getAllBanks'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAllBanks', formData, '', 'plugin/flutterwave/payment/bank'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BANKS,
                    payload: res.data.data.result,
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const createBankAccount = async (formData) => {
        const from = 'createBankAccount'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'createBankAccount',
                    formData,
                    '',
                    'plugin/flutterwave/payment/bank',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: res.data.data.message,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getRefundlist = async (formData) => {
        const from = 'refundlist'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'refund_request_list',
                    formData,
                    '',
                    'plugin/flutterwave/payment/bank',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        responseData: res?.data?.data?.product ? res.data.data.product : [],
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const RefundlistAccepted = async (formData) => {
        const from = 'refundlistaccepted'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'flutterwaveRefund',
                    formData,
                    '',
                    'plugin/payment_geteway/stripe/refund',
                ),
            ])
            // if (res.data.status === 'success') {
            resp.commonResponse(
                {
                    status: res?.data?.status ? res.data.status : 'error',
                    message: res?.data?.data?.message
                        ? res.data.data.message
                        : 'something went wrong!',
                },
                from,
            )
            // } else {
            //     resp.commonResponse({ status: 'error', message: res.data?.data?.message }, from)
            // }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const Refundlistrejected = async (formData) => {
        const from = 'refundlistreject'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'reject_refund', formData, '', 'plugin/flutterwave/payment/bank'),
            ])
            // if (res.data.status === 'success') {
            resp.commonResponse(
                {
                    status: res?.data?.status ? res.data.status : 'error',
                    message: res?.data?.data?.message
                        ? res.data.data.message
                        : 'something went wrong!',
                },
                from,
            )
            // } else {
            //     resp.commonResponse({ status: 'error', message: res.data?.data?.message }, from)
            // }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getDistputslist = async (formData) => {
        const from = 'distputslist'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'dispute_list', formData, '', 'plugin/flutterwave/payment/bank'),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        responseData: res?.data?.data?.product ? res.data.data.product : [],
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getDistputslistReject = async (formData) => {
        const from = 'distputslistcancel'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'dispute_list_cancel',
                    formData,
                    '',
                    'plugin/flutterwave/payment/bank',
                ),
            ])
            // console.log('pppppppppppppppppppppppppppppppppppppppppppp', res.data)
            // if (res.data.status === 'success') {
            resp.commonResponse(
                {
                    status: res?.data?.status ? res.data.status : 'error',
                    message: res?.data?.data?.message
                        ? res.data.data.message
                        : 'something went wrong!',
                },
                from,
            )
            // } else {
            //     resp.commonResponse({ status: 'error', message: res.data.data.message }, from)
            // }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const requestRefundaccept_or_not = async (formData) => {
        const from = 'requestRefundaccpt_or_not'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'refund_request_approve_admin',
                    formData,
                    '',
                    'plugin/flutterwave/payment/bank',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Updated Successfully',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Something Went Wrong!' }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const requestRefund_seller = async (formData) => {
        const from = 'requestRefund_seller'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'refund_request_seller_admin',
                    formData,
                    '',
                    'plugin/flutterwave/payment/bank',
                ),
            ])
            if (res.data.status === 'success') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Updated Successfully',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Something Went Wrong!' }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <PluginContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_messages: state.all_messages,
                all_referrals: state.all_referrals,
                total_earned: state.total_earned,
                feedback_fields: state.feedback_fields,
                all_feedback: state.all_feedback,
                single_feedback: state.single_feedback,
                all_banks: state.all_banks,
                requestTrackingNumber,
                createBankAccount,
                getproFeedback,
                getAllFeedback,
                getFeedbackField,
                addFeedback,
                createReferral,
                getReferralList,
                requestRefund,
                getdonarexistingcard,
                getdonarlist,
                donar_registration,
                clearResponse,
                subscribeReseller,
                paypalLogin,
                requestShipping,
                composeMessage,
                getAllMessages,
                get_Stage,
                check_ticket_avialable,
                book_tickets,
                get_booked_tickets,
                requestdiscount,
                getPlanDetails,
                disputeMessage,
                getAllBanks,
                getRefundlist,
                RefundlistAccepted,
                Refundlistrejected,
                getDistputslist,
                getDistputslistReject,
                subscribeBuyer,
                requestRefundaccept_or_not,
                requestRefund_seller,
            }}
        >
            {props.children}
        </PluginContext.Provider>
    )
}

export default ProductState
