import React, { useEffect, useState, useContext, useRef } from 'react'
import './searchItems.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import GridProduct from '../../../Component/ProductCard/gridProduct'
import Layout from '../Layout'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import FilterPanel from '../../../Product/components/organisms/FilterPanel'
import Loaders from '../../../Product/components/molecules/Loaders'
import { Button, Drawer, SwipeableDrawer } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SlidePanel from '../../../Component/SlidePanel'
import MultisellerListCard from '../../../Component/ProductCard/MultisellerListCard'
import MultisellerSlider from '../../../Component/SlidePanel/MultisellerSlider'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import { messageHandler } from '../../../Product/common/socketHandler'
import AlertContext from '../../../Product/context/alert/alertContext'
import { Pagination } from '@material-ui/lab'
import { socket, socketAuction } from '../../../Product/common/socket'
import Slider from 'react-slick'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory, useLocation } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HeaderSearchComponent from '../Header/HeaderSearch'
import { sustainabilityOptions } from '../../../utils'
import { Box, ListItem, Tab, Tabs, Typography } from '@material-ui/core'
import GridAuction from '../../../Component/ProductCard/gridAuction'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { setAuthToken } from '../../../Product//common/api'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `home-auctions-${index}`,
        'aria-controls': `home-auctions-tabpanel-${index}`,
    }
}

const AuctionSearchComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const history = useHistory()
    const location = useLocation()

    const productContext = useContext(ProductContext)
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { search_allproducts, getAllSearchProducts } = productContext
    const [auctionView, setAuctionView] = useState('Grid')
    const { t } = useTranslation()
    const { isAuthenticated, user, responseStatus } = authContext
    const { setAlert } = alertContext

    const classes = useStyles()

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalItems, setTotalItem] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [date_now, setNowdate] = useState('')
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const [value, setValue] = useState(0)

    const forMobile = useMediaQuery('(max-width:1024px)')

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        let date_now_day = new Date()
        date_now_day.setDate(date_now_day.getDate() + 7)
        setNowdate(date_now)
    }, [])

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
        }
    }, [])

    const active_sorts = {
        sorts: {
            sortcategoryTypes: ['2', '4', '5', '7', '8'],
            sortconditionTypes: ['2', '3', '5', '6'],
            sortsubCategoryTypes: ['1', '2', '3'],
            sortlocations: ['1', '2', '3', '18', '20'],
            sortall_notifications: [],
            sortbusiness_category: [],
            sortvat_registration: [],
        },
    }
    const [searchbar] = useState(new URLSearchParams(window.location.search.substring(1)).get('q'))
    const [catg] = useState(new URLSearchParams(window.location.search.substring(1)).get('cat'))
    const params = new URLSearchParams(window.location.search.substring(1))

    const formik = useFormik({
        initialValues: {
            sh_limit: 55,
            page: 1,
            orderby: '6',
            location: [],
            state: [],
            city: [],
            category: params.get('cat') ? params.get('cat') : '',
            contenthead5: [],
            subcategory: [],
            condition: [],
            storedate: [],
            enddate: null,
            fromprice: 0,
            toprice: 0,
            totalpage: 5,
            lotof: '',
            searchbar: searchbar ? searchbar : '',
            miles: params.get('m') ? params.get('m') : '',
            // from: 'auction_only',
            closed_auction_list: 0,
            view_type: 0,
            auction_io: 1,
            current: 0,
            upcoming: 0,
        },
    })

    const filterValues = [
        {
            title: 'View',
            type: 'radio',
            noLabel: true,
            name: 'view_type',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'All Items' },
                { id: 1, description: 'Single Lots' },
                { id: 2, description: 'Buy Now' },
            ],
            formik: formik,
        },

        {
            title: 'Category',
            type: 'check',
            name: 'category',
            options: global.searchCategories
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },
        {
            title: 'Sustainability',
            type: 'check',
            name: 'contenthead5',
            options: sustainabilityOptions
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.description = buscat.description
                    busCategoryChanged.name = buscat.description
                    busCategoryChanged.value = buscat.id
                    busCategoryChanged.id = buscat.id
                    busCategoryChanged.active = 1
                    return busCategoryChanged
                })
                .sort((a, b) => a.description.localeCompare(b.description))
                .filter((main) => {
                    return true
                }),
            formik: formik,
        },

        {
            title: 'Auction Status',
            type: 'radio',
            noLabel: true,
            name: 'closed_auction_list',
            int: 1,
            class: 'col-12',
            item: [
                { id: 0, description: 'Live Items' },
                { id: 1, description: 'Closed Items' },
            ],
            formik: formik,
        },

        // {
        //     title: 'Sub category',
        //     type: 'check',
        //     name: 'subcategory',
        //     options: global.searchSubCategories.filter((subcat) => {
        //         // if (
        //         //     formik &&
        //         //     formik.values &&
        //         //     formik.values.category &&
        //         //     formik.values.category.length
        //         //         ? formik.values.category.includes(subcat.level_id.toString())
        //         //         : false
        //         // ) {
        //         return true
        //         //}
        //     }),
        //     formik: formik,
        // },
    ]

    useEffect(() => {
        // if (search_allproducts.from === 'searchPage') {
        setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        setTotalItem(search_allproducts.total_pagecnt)
        // }
        if (!search_allproducts.loading) {
            setLoading(false)
        }
    }, [search_allproducts])

    useEffect(() => {}, [])
    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        formik.setFieldValue('page', value)
        var data = formik.values
        data.page = value
        getAllSearchProducts(data)
    }
    // useEffect(() => {
    //     setLoading(true)
    //     if (formik.values.closed_auction_list) {
    //         formik.values.orderby = '7'
    //     }

    //     if (formik.values.view_type == 1) {
    //         formik.setFieldValue('from', 'auction_only')
    //     } else if (formik.values.view_type == 2) {
    //         formik.setFieldValue('from', 'buynow')
    //     } else formik.setFieldValue('from', '')
    // }, [formik.values])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search.substring(1))
        formik.setFieldValue('miles', params.get('m') ? params.get('m') : '')
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        if (params.get('product')) {
            setSelectedProduct(params.get('product'))
        }
    }, [window.location.search])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const handleClose = () => {
        params.delete('product')
        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        })
        setSelectedProduct(null)
    }

    const handleClick = async (id) => {
        params.set('product', id)

        history.replace({
            pathname: location.pathname,
            search: params.toString(),
        })
    }

    const pageOptions = [
        {
            value: '12',
            show: 'Results 12 per page',
        },
        {
            value: '24',
            show: 'Results 24 per page',
        },
        {
            value: '36',
            show: 'Results 36 per page',
        },
    ]

    const sortShow = [
        {
            value: '',
            show: 'None',
        },
        {
            value: 3,
            show: 'Current price low to high',
        },
        {
            value: 11,
            show: 'Current price high to low',
        },
        {
            value: 6,
            show: 'Time remaining',
        },
        {
            value: 2,
            show: 'Recently listed',
        },
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const isDesktop = useMediaQuery('(min-width: 769px)')

    useEffect(() => {
        // console.log('formik.values', formik.values)
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        if (value === 0) {
            formik.values.page = 1
            ;(formik.values.upcoming = 0), (formik.values.current = 1), setViewProduct([])
            formik.values.category = params.get('cat') ? params.get('cat') : ''
            getAllSearchProducts(formik.values)
        } else if (value === 1) {
            formik.values.page = 1
            ;(formik.values.upcoming = 1), (formik.values.current = 0), setViewProduct([])
            formik.values.category = params.get('cat') ? params.get('cat') : ''
            getAllSearchProducts(formik.values)
        }
    }, [value, params.get('cat')])
    useEffect(() => {
        setLoading(true)
        getAllSearchProducts(formik.values)
    }, [formik.values.orderby])

    useEffect(() => {
        setLoading(true)
        formik.setFieldValue('searchbar', params.get('q') ? params.get('q') : '')
        var body_val = formik.values
        body_val.searchbar = params.get('q') ? params.get('q') : ''
        getAllSearchProducts(formik.values)
    }, [params.get('q')])

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'login') {
    //             if (responseStatus.status === 'success') {
    //                 getAllSearchProducts(formik.values)
    //             }
    //         }
    //     }
    // }, [responseStatus])

    return (
        <Layout props={props}>
            <div className="customContainer mt-5 position-relative">
                <div className="topHedr">
                    {t('available')} <span>COLLECTIBLES</span>
                </div>
                {forMobile ? (
                    <div className="mblStckyFilter d-flex justify-content-between align-items-center py-3 flex-wrap">
                        {/* <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            Filters
                        </Button> */}

                        <CustomSelect
                            label="Sort by"
                            value={formik.values.orderby}
                            size="small"
                            selectType="noBorder"
                            name="orderby"
                            // shrink={search.orderby !== '' ? true : false}
                            onChange={(event, editor) => {
                                formik.setFieldValue('orderby', event.target.value)
                            }}
                        >
                            {sortShow.map((opt, optindex) => (
                                <option value={opt.value}>{opt.show}</option>
                            ))}
                        </CustomSelect>

                        {/* <Button
                            className="filterButton w-100"
                            variant="outlined"
                            onClick={() => handleRedirectInternal(history, 'dashboard/mybids')}
                        >
                            My Bids
                        </Button> */}

                        {/* <Button
                            className="filterButton srchBtn w-100"
                            variant="outlined"
                            onClick={toggleDrawer('top', true)}
                        >
                            <span className="material-icons">search</span>
                        </Button> */}
                    </div>
                ) : (
                    <div className="d-flex justify-content-between align-items-center flex-wrap my-5">
                        <h2 className="auctionListTitle"></h2>

                        <div className="auctionsMiscFilter">
                            <CustomSelect
                                label="Sort by"
                                value={formik.values.orderby}
                                size="small"
                                selectType="noBorder"
                                name="orderby"
                                // shrink={search.orderby !== '' ? true : false}
                                onChange={(event, editor) => {
                                    formik.setFieldValue('orderby', event.target.value)
                                }}
                            >
                                {sortShow.map((opt, optindex) => (
                                    <option value={opt.value}>{opt.show}</option>
                                ))}
                            </CustomSelect>
                        </div>
                        <Button
                            className="toggleRespDrawer w-100"
                            variant="outlined"
                            onClick={toggleDrawer('bottom', true)}
                        >
                            <span className="material-icons">tune</span>
                            Filters
                        </Button>
                    </div>
                )}

                <div className="homeMainCntnr">
                    <Tabs value={value} onChange={handleChange} aria-label="home-auctions">
                        <Tab label={t('current_lot')} {...a11yProps(0)} />
                        <Tab label={t('upcoming_lot')} {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {isLoading ? (
                            <div className="homeLoader">
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={9} />
                            </div>
                        ) : viewProduct.filter(
                              (data) => data.market_status == 'open' && data.remainingDay >= 0,
                          ).length !== 0 ? (
                            <div className={`homeAuctions ${!isDesktop && 'mobileView'}`}>
                                {viewProduct.map((data, index) => (
                                    <>
                                        {
                                            <GridProduct
                                                data={data}
                                                className={data.auction ? 'auction' : 'buynow'}
                                                key={index}
                                                type={data.auction ? 'auction' : 'buynow'}
                                                selectedProduct={selectedProduct}
                                                link={`/gallery/product-view/${data.id}`}
                                                handleClick={handleClick}
                                                timerTheme="ArtAuction"
                                                sliderTheme="multiseller"
                                                additionalCardTheme="collectibles"
                                                need_end_date={1}
                                                next_week_date={date_now}
                                                cardTheme={data.auction ? 'ArtAuction' : ''}
                                                bidBtnLabel="Place Bid"
                                                viewProduct={viewProduct}
                                                setViewProduct={setViewProduct}
                                            />
                                        }
                                    </>
                                ))}
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {isLoading ? (
                            <div className="homeLoader">
                                <Loaders name="product_grid_view" isLoading={isLoading} loop={9} />
                            </div>
                        ) : viewProduct.filter(
                              (data) => data.market_status == 'open' && data.remainingDay >= 0,
                          ).length !== 0 ? (
                            <div className={`homeAuctions ${!isDesktop && 'mobileView'}`}>
                                {viewProduct.map((data, index) => (
                                    <>
                                        {
                                            <GridProduct
                                                data={data}
                                                className={data.auction ? 'auction' : 'buynow'}
                                                key={index}
                                                type={data.auction ? 'auction' : 'buynow'}
                                                selectedProduct={selectedProduct}
                                                link={`/gallery/product-view/${data.id}`}
                                                handleClick={handleClick}
                                                timerTheme="ArtAuction"
                                                sliderTheme="multiseller"
                                                cardTheme="ArtAuction"
                                                bidBtnLabel="Place Bid"
                                                viewProduct={viewProduct}
                                                setViewProduct={setViewProduct}
                                                need_end_date={1}
                                            />
                                        }
                                    </>
                                ))}
                            </div>
                        ) : (
                            <NoRecordsFound />
                        )}
                    </TabPanel>
                </div>

                <div className="searchContainer">
                    <Pagination
                        count={Math.ceil(totalItems / formik.values.sh_limit)}
                        page={formik.values.page}
                        onChange={onHandlePage}
                    />
                </div>
            </div>

            <Drawer
                anchor="bottom"
                open={state['bottom']}
                className="responsiveFilterDrawer"
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
            >
                <div
                    className={clsx(classes.list, 'bottom')}
                    role="presentation"
                    // onClick={toggleDrawer('bottom', false)}
                    onKeyDown={toggleDrawer('bottom', false)}
                >
                    <FilterPanel formik={formik} filterValues={filterValues} />
                </div>
            </Drawer>
            <MultisellerSlider
                storeTheme="ArtAuction"
                selectedLot={selectedProduct}
                handleClose={handleClose}
                type={props.type ? props.type : ''}
                conditions={'Collectibles'}
            />
            <React.Fragment>
                <SwipeableDrawer
                    className="respHeaderSearch"
                    anchor={'top'}
                    open={state['top']}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                    disableSwipeToOpen={false}
                    onClose={toggleDrawer('top', false)}
                    onOpen={toggleDrawer('top', true)}
                >
                    <HeaderSearchComponent />
                </SwipeableDrawer>
            </React.Fragment>
        </Layout>
    )
}

export default AuctionSearchComponent
