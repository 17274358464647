import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AlertContext from '../../../Product/context/alert/alertContext'
import ProductContext from '../../../Product/context/product/productContext'
import CheckBox from '../../../Product/components/atoms/CheckBox'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData, handleRedirectInternal, converDate } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import CustomSelect from '../../../Product/components/atoms/Inputs/CustomSelect'
import RadioBox from '../../../Product/components/atoms/RadioBox'
import CustomInput from '../../../Product/components/atoms/Inputs/CustomInput'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'

const CommonReports = (props) => {
    const productContext = useContext(ProductContext)
    const { report, responseStatus } = productContext
    const { t } = useTranslation()
    const [values, setValues] = useState({
        report: '',
        order: '',
        limit: '',
        option: '',
        auctiondate: converDate(new Date()),
        type: '',
        name: '',
        fromdate: converDate(new Date()),
        todate: converDate(new Date()),
    })
    const [fields, setFields] = useState([])
    const groupOptions = [
        {
            title: 'Sold',
            options: [
                {
                    value: 'sold_items',
                    show: 'Sold Items',
                    check: [
                        { id: 'product_id', description: 'Product Id', value: 1 },
                        { id: 'title', description: 'Title', value: 1 },
                        { id: 'buyer_name', description: 'Buyer name', value: 1 },
                        { id: 'buyer_email', description: 'Buyer Email', value: 1 },
                        { id: 'sale_date', description: 'Sale Date', value: 1 },
                        { id: 'price', description: 'Price', value: 1 },
                    ],
                },
            ],
        },
        {
            title: 'Invoice',
            options: [
                {
                    value: 'invoice_paid',
                    show: 'Invoice Paid',
                    check: [
                        { id: 'invoice_id', description: 'Invoice Id', value: 1 },
                        { id: 'title', description: 'Title', value: 1 },
                        { id: 'buyer_name', description: 'Buyer name', value: 1 },
                        { id: 'buyer_email', description: 'Buyer Email', value: 1 },
                        { id: 'sale_date', description: 'Sale Date', value: 1 },
                        { id: 'price', description: 'Price', value: 1 },
                    ],
                },
                {
                    value: 'invoice_unpaid',
                    show: 'Invoice Unpaid',
                    check: [
                        { id: 'invoice_id', description: 'Invoice Id', value: 1 },
                        { id: 'title', description: 'Title', value: 1 },
                        { id: 'buyer_name', description: 'Buyer name', value: 1 },
                        { id: 'buyer_email', description: 'Buyer Email', value: 1 },
                        { id: 'sale_date', description: 'Sale Date', value: 1 },
                        { id: 'price', description: 'Price', value: 1 },
                    ],
                },
            ],
        },
    ]
    const withinSelect = [
        { value: 'day', show: 'Past Day' },
        { value: 'week', show: 'Past Week' },
        { value: 'month', show: 'Past Month' },
        { value: 'year', show: 'Past Year' },
    ]
    const orderRadio = [
        { id: 'asc', description: 'Ascending' },
        { id: 'desc', description: 'Descending' },
    ]
    const optionRadio = [
        { id: 'auctiondate', description: 'Auction Date' },
        { id: 'fixed', description: 'Within' },
        { id: 'range', description: 'Range' },
    ]
    const handleSelectChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        groupOptions.map((val) =>
            val.options.find((val) => val.value === e.target.value && setFields(val.check)),
        )
    }
    const handleCheckChange = (index) => {
        var arr = fields
        arr[index].value = arr[index].value === 0 ? 1 : 0
        // console.log(arr)
        setFields([...arr])
    }
    const handleRadioChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })
    const handleInputChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

    const handleSubmit = () => {
        if (values.report == 'invoice_paid') {
            values.name = 'Invoice Paid'
        } else if (values.report == 'invoice_unpaid') {
            values.name = 'Invoice UnPaid'
        } else if (values.report == 'sold_items') {
            values.name = 'Sold Items'
        }
        const body = {
            ...values,
        }
        fields.map((val) => (body[val.id] = val.value))
        report(body)
    }
    return (
        <div className="preferences-form-wrapper pt-3">
            <hr />
            <h6>{t('report_page_text')}</h6>
            <hr />
            <CustomSelect
                label={t('report')}
                id="report"
                value={values.report}
                name="report"
                onChange={handleSelectChange}
                placeholder="Select report to generate"
                // upperLabel="Report"
            >
                <option value="">Select report to generate</option>
                {groupOptions.map((val, key) => (
                    <optgroup label={val.title} key={key}>
                        {val.options.map((val, key) => (
                            <option key={key} value={val.value}>
                                {val.show}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </CustomSelect>

            {values.report && (
                <>
                    <hr />
                    <h6>Customize your report by viewing only desired transaction columns.</h6>
                    <hr />
                    <div className="row">
                        {fields.map((data, key) => (
                            <div className="col-md-3">
                                <CheckBox
                                    key={key}
                                    name={data.id}
                                    label={data.description}
                                    value={data.id}
                                    onChange={() => handleCheckChange(key)}
                                    checked={data.value}
                                />
                            </div>
                        ))}
                    </div>
                    <hr />
                    <h6>Customize your report using a wide variety of date range options.</h6>
                    <hr />
                    <RadioBox
                        title="Range options"
                        name="option"
                        items={optionRadio}
                        value={values.option}
                        onChange={handleRadioChange}
                    />
                    {values.option === 'auctiondate' && (
                        <KeyboardDatePicker
                            margin="0"
                            autoOk={true}
                            showTodayButton={true}
                            id="auctiondate"
                            inputVariant="outlined"
                            label="Auction Date"
                            format="YYYY-MM-DD"
                            placeholder="YYYY-MM-DD"
                            className="customDatepicker"
                            value={values.auctiondate}
                            inputValue={values.auctiondate}
                            onChange={(val) =>
                                setValues({ ...values, auctiondate: converDate(val) })
                            }
                        />
                    )}
                    {values.option === 'fixed' && (
                        <CustomSelect
                            label="type"
                            id="type"
                            value={values.type}
                            name="type"
                            onChange={handleSelectChange}
                        >
                            <option value="">Select</option>
                            {withinSelect.map((val, key) => (
                                <option key={key} value={val.value}>
                                    {val.show}
                                </option>
                            ))}
                        </CustomSelect>
                    )}
                    {values.option === 'range' && (
                        <div className="row">
                            <div className="col">
                                <KeyboardDatePicker
                                    margin="0"
                                    autoOk={true}
                                    showTodayButton={true}
                                    id="fromdate"
                                    inputVariant="outlined"
                                    label="From Date"
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    className="customDatepicker"
                                    value={values.fromdate}
                                    inputValue={values.fromdate}
                                    onChange={(val) =>
                                        setValues({ ...values, fromdate: converDate(val) })
                                    }
                                />
                            </div>
                            <div className="col">
                                <KeyboardDatePicker
                                    margin="0"
                                    autoOk={true}
                                    showTodayButton={true}
                                    id="todate"
                                    inputVariant="outlined"
                                    label="To Date"
                                    format="YYYY-MM-DD"
                                    placeholder="YYYY-MM-DD"
                                    className="customDatepicker"
                                    value={values.todate}
                                    inputValue={values.todate}
                                    onChange={(val) =>
                                        setValues({ ...values, todate: converDate(val) })
                                    }
                                />
                            </div>
                        </div>
                    )}
                    <hr />
                    <h6>Order your results based on ascending or descending below.</h6>
                    <hr />
                    <RadioBox
                        title="Order Results"
                        name="order"
                        items={orderRadio}
                        value={values.order}
                        onChange={handleRadioChange}
                    />
                    <CustomInput
                        id="limit"
                        value={values.limit}
                        name="limit"
                        shrink={true}
                        onChange={handleInputChange}
                        label="Limit Results"
                        placeholder="100"
                        type="number"
                    />
                    <PrimaryButton type="button" label={'Generate'} onClick={handleSubmit} />
                </>
            )}
        </div>
    )
}

export default CommonReports
