import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import DashboardLayout from '../DashboardLayout'
import Layout from '../Layout'
import ReferralFunction from '../../../utils/CommonFunctionality/Refund'

const Refund = (props) => {
    return (
        <Layout props={props}>
            <DashboardLayout title="Return">
                <Container className="dashboard-wrapper">
                    <Grid container spacing={3}>
                        <ReferralFunction />
                    </Grid>
                </Container>
            </DashboardLayout>
        </Layout>
    )
}

export default Refund
