import React from 'react'
import Container from '@material-ui/core/Container'
import './subscription.css'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import authContext from '../../../Product/context/auth/authContext'
import ReactHtmlParser from 'react-html-parser'
import moment from 'moment'
const BuyerAssurance = (props) => {
    const { user } = useContext(authContext)
    return (
        <Container className="db-bids-wrapper mt-4">
            <h2 className="mp-head">Join your subscription plan</h2>
            <div className="subCard my-5">
                <div className="liquidationSubCard">
                    <div className="card grid-card">
                        <div className="grid-img-wrap">
                            <img src="/assets/images/subCard.png"></img>
                        </div>
                        <div className="card-body p-4">
                            <h3 className="premium">
                                {user?.buyer_subscribed == 1
                                    ? user?.buyer_subscribe_plan
                                    : global?.pluginConfiguration?.buyers_assurance?.end_point_url}
                            </h3>
                            {user?.buyer_subscribed == 1 ? null : (
                                <h2 className="text-center my-4">
                                    <b>
                                        ${' '}
                                        {global?.pluginConfiguration?.buyers_assurance?.client_key}
                                    </b>
                                    /mo
                                </h2>
                            )}
                            {user?.buyer_subscribed == 1 ? (
                                <p>
                                    Subscribed on {moment(user?.buyer_subscribed_at).format('LL')}
                                </p>
                            ) : (
                                ReactHtmlParser(
                                    global?.pluginConfiguration?.buyers_assurance?.service_key,
                                )
                            )}
                            {user?.buyer_subscribed == 1 ? (
                                <button type="button" className="yesButton">
                                    Already Subscribed
                                </button>
                            ) : (
                                <div className="d-flex justify-content-between align-items-center mx-4">
                                    <button type="button" className="yesButton">
                                        <NavLink activeClassName="active" to="/dashboard/billing">
                                            Yes
                                        </NavLink>
                                    </button>
                                    <p className="mb-0 fonting">or</p>
                                    <p className="mb-0 fonting later">Later</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default BuyerAssurance
