import React, { useEffect, useState, useContext } from 'react'
import './gallery.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'
import Loaders from '../../../Product/components/molecules/Loaders'

const GalleryComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])

    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)

    const { search_allproducts, getAllSearchProducts } = productContext

    const { isAuthenticated } = authContext

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)

    const [productSearch, setProductSearch] = useState({
        sh_limit: 12,
        from: 'buynow',
        page: 1,
        orderby: '1',
    })

    useEffect(() => {
        getAllSearchProducts(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        if (search_allproducts.from === 'galleryPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
    }

    return (
        <Layout props={props}>
            <div className="customContainer mt-5">
                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                ) : viewProduct.length > 0 ? (
                    <>
                        <h2 className="buyNowTitle">Buy Now Items</h2>
                        <SearchProductList
                            listview={listview}
                            searchResult={viewProduct}
                            className="buynow"
                            type="buynow"
                            noTimer={true}
                            // currency="cad"
                            // cardTheme="ArtAuction"
                            sliderTheme="multiseller"
                        />
                    </>
                ) : (
                    <NoRecordsFound />
                )}
                {/* <SimilarList /> */}
            </div>
        </Layout>
    )
}

export default GalleryComponent
