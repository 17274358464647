import React, { useState, useEffect, useContext, useRef, Fragment } from 'react'
import ReadMoreReact from 'read-more-react'
import ImageGallery from 'react-image-gallery'
import Toaster from '../Toaster'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import { useHistory, useParams, Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Bidhistory from '../Bidhistory'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BuyerContext from '../../Product/context/buyer/buyerContext'
import AuthContext from '../../Product/context/auth/authContext'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CartContext from '../../Product/context/cart/cartContext'
import AlertContext from '../../Product/context/alert/alertContext'
import StripeCardContext from '../../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../../Product/context/directStripe/directStripeContext'
import Loaders from '../../Product/components/molecules/Loaders'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import GridProduct from '../ProductCard/gridProduct'
import Timer from '../../Product/common/timer'
import {
    messageHandlerSingle,
    make_an_offer_update_socket,
} from '../../Product/common/socketHandler'
import { socket, socketAuction } from '../../Product/common/socket'
import { makeStyles } from '@material-ui/core/styles'

import { Button, Drawer, Popover } from '@material-ui/core'
import { currencyFormat, handleRedirectInternal, mapData } from '../../Product/common/components'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import './slider.css'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
    allCondition,
    storageOptions,
    unitOptions,
    brandsliqutatios,
    collectibleConditions,
} from '../../utils'
import HowBidPopup from '../HowBiddingWorks'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import BidStatus from '../../Product/components/molecules/Bidding/BidStatus'
import MessagePlugin from '../../utils/CommonFunctionality/MessagePlugin'
import NoRecordsFound from '../../Product/components/atoms/NoRecordsFound'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import { imageError } from '../../utils/commonFunctions'
import UserContext from '../../Product/context/user/userContext'
import ProductContext from '../../Product/context/product/productContext'
import SimilarListComponent from '../Similaritem'
import { useFormik } from 'formik'
import * as Yup from 'yup'

export function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div className="tabBody">{children}</div>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: '10px 0px',
    },
}))(MuiAccordionDetails)

const Standalone = ({
    type,
    from,
    getAllProducts,
    selectedLot,
    handleClose,
    viewPage,
    storeTheme,
    theme,
    lotDetails,
    view_more_need,
    conditions,
}) => {
    const buyerContext = useContext(BuyerContext)
    const cartContext = useContext(CartContext)
    const alertContext = useContext(AlertContext)
    const { usercards } = useContext(DirectStripeContext)
    const [productcategory, setProductcategory] = useState('')
    const [productsubcategory, setProductsubcategory] = useState('')
    const {
        getOverAllCategories,
        categories,
        getLotList,
        search_allauctionproducts,
        insert_make_proposels,
    } = useContext(ProductContext)
    const [followornot, setFolloworNot] = useState(1)
    const { follow } = useContext(UserContext)
    const [addfav, setFav] = useState(0)
    const [similaritem, setSimlaritems] = useState([])
    const [totalSimilarItem, setTotalSimilaritem] = useState([])
    const authContext = useContext(AuthContext)
    const { get_all_card_details } = useContext(StripeCardContext)
    const { setAlert } = alertContext
    const [value, setValue] = useState(0)
    const [cards, setCards] = useState([])

    const { user, isAuthenticated } = authContext
    const { buyer_allproducts, getAllBuyerProducts, product_view, getViewProduct } = buyerContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const history = useHistory()
    const [historyData, setHistoryData] = useState({})
    const [loading, setLoading] = useState(true)
    const [loaded_item, setLoaded] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [selectedProductImage, setSelectedProductImage] = useState([])
    const [selectedProduct, setSelectedProduct] = useState({})
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const { id } = useParams()
    const matches = useMediaQuery('(max-width:800px)')
    const [howBidPopup, setHowBidPopup] = useState(false)
    const [expanded, setExpanded] = React.useState(false)
    const [viewProduct, setViewProduct] = useState([])
    const [openSocialShare, setOpenSocialShare] = useState(null)

    const AccordionHandleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const isArtTheme = document.querySelector('body').classList.contains('artTheme')

    const SocialShareClick = (event) => {
        setOpenSocialShare(event.currentTarget)
    }

    const SocialShareClock = () => {
        setOpenSocialShare(null)
    }

    const popoverid = open ? 'simple-popover' : undefined

    useEffect(() => {
        if (selectedLot) {
            var data = {
                product_id: selectedLot,
                need_followflag: theme === 'Artauction' ? 1 : undefined,
            }
            if (theme == 'multiAuction') {
                data.crop_auction_type = 'buyer'
            }
            getViewProduct(data)
        }
    }, [selectedLot])
    useEffect(() => {
        if (id) {
            getViewProduct({
                product_id: id,
                need_followflag: theme === 'Artauction' ? 1 : undefined,
            })
            if (theme === 'Artauction') {
                getOverAllCategories({}, global?.storeConfigration?.cat_mis_match?.value)
            }
        }
    }, [id])

    useEffect(() => {
        if (categories.length > 0) {
            if (
                categories.filter(
                    (val) =>
                        parseInt(val.id) ==
                        parseInt(selectedProduct?.category_id ? selectedProduct.category_id : 0),
                ).length > 0
            ) {
                setProductcategory(
                    categories.filter(
                        (val) =>
                            parseInt(val.id) ==
                            parseInt(
                                selectedProduct?.category_id ? selectedProduct.category_id : 0,
                            ),
                    )[0].name,
                )
                setProductsubcategory(
                    categories.filter(
                        (val) =>
                            parseInt(val.id) ==
                            parseInt(
                                selectedProduct?.category_id ? selectedProduct.category_id : 0,
                            ),
                    )[0].filters.length > 0
                        ? categories
                              .filter(
                                  (val1) =>
                                      parseInt(val1.id) ==
                                      parseInt(
                                          selectedProduct?.category_id
                                              ? selectedProduct.category_id
                                              : 0,
                                      ),
                              )[0]
                              .filters.filter(
                                  (val1) =>
                                      parseInt(val1.id) ==
                                      parseInt(
                                          selectedProduct?.subcategory
                                              ? selectedProduct.subcategory
                                              : 0,
                                      ),
                              )[0].name
                        : '',
                )
            } else {
                setProductcategory('')
                setProductsubcategory('')
            }
        } else {
            setProductcategory('')
            setProductsubcategory('')
        }
    }, [categories])

    const userFollow = (followed, user_id) => {
        if (!isAuthenticated) {
            return handleRedirectInternal(history, 'login')
        }
        //setFolloworNot(followed)
        follow({ follow: followed === 1 ? 0 : 1, user_id: user_id }, setFolloworNot)
    }

    // console.log(product_view, 'product_view')
    useEffect(() => {
        if (product_view.results) {
            if (product_view.results.work_loc) {
                product_view.results.documents = product_view.results.work_loc.split(',')
            }
            if (product_view.results.custom_field_8) {
                const addr = JSON.parse(product_view.results.custom_field_8)
                product_view.results.address = addr.address
                product_view.results.zipcode = addr.zipcode
            }
            if (product_view.results.custom_field_9) {
                const food = JSON.parse(product_view.results.custom_field_9)
                product_view.results.storage = food.storage
                product_view.results.package_date = food.package_date
                product_view.results.selling_unit = food.selling_unit
                product_view.results.total_weight = food.total_weight
            }
            if (
                product_view?.results?.follow != null &&
                product_view?.results?.follow != undefined
            ) {
                setFolloworNot(parseInt(product_view.results.follow))
            }
            setSelectedProduct({
                ...product_view.biddetails,
                ...product_view.results,
                incrementamt: product_view.incrementamt,
            })
            if (product_view?.attachments.length) {
                product_view?.attachments.forEach(function (data) {
                    data['original'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/' + data.src
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL + data.src
                    }`

                    data['thumbnail'] = `${
                        product_view.results?.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        data.src
                    }`
                })
                setSelectedProductImage(product_view.attachments)
            } else {
                setSelectedProductImage([
                    {
                        original: global?.storeDetails?.logoValue,
                        thumbnail: global?.storeDetails?.logoValue,
                    },
                ])
            }
            if (theme == 'multiAuction') {
                setTimeout(() => {
                    setLoading(false)
                }, [2000])
            } else {
                setLoading(false)
            }
            if (product_view.results.category_id && view_more_need) {
                getLotList(
                    {
                        page: 1,
                        orderby: '2',
                        lotof: product_view.results.lotof,
                        limit: 150,
                        market_status: 'open',
                        auction_io: 1,
                        searchquery: product_view.results.category_id,
                    },
                    'productviewAuction',
                )
            }
        }
    }, [product_view.results])

    useEffect(() => {
        if (search_allauctionproducts.from === 'productviewAuction') {
            setSimlaritems(
                search_allauctionproducts.results.length > 0
                    ? search_allauctionproducts.results.filter(
                          (val) => parseInt(val.id) != parseInt(product_view.results.id),
                      )
                    : [],
            )
            setTotalSimilaritem(search_allauctionproducts.total_only)
            setLoaded(false)
        }
    }, [search_allauctionproducts])

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (selectedProduct.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: selectedProduct.id,
                })
                position === -1 ? setDisabled(false) : setDisabled(true)
            }
        }
    }, [buyer_cartitems.results])

    const handleClick = async (e) => {
        if (!cards.length && global.storeDetails?.theme != 4) {
            setAlert('Add a card to buy!', 'error')
            return handleRedirectInternal(history, 'dashboard/cards')
        }
        addToCart({ id: selectedProduct.id, qty: selectedProduct.qty })
    }
    useEffect(() => {
        if (get_all_card_details) {
            setCards(get_all_card_details.records)
        }
    }, [get_all_card_details])
    useEffect(() => {
        if (usercards?.length) {
            setCards(usercards)
        }
    }, [usercards])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    const viewProductRef = useRef(selectedProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = selectedProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        // console.log('reverse handler ', message, viewProductRef.current)
        messageHandlerSingle(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    const multiseller_handler = (message, type) => {
        if (parseInt(viewProductRef.current.content_head5) == 0) {
            messageHandlerSingle(
                message,
                viewProductRef.current,
                userRef.current,
                setAlert,
                setSelectedProduct,
                type,
            )
        }
    }

    const make_an_offer_update_new = (message, type) => {
        make_an_offer_update_socket(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setSelectedProduct,
            type,
        )
    }

    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                data.id = data.pid

                if (
                    parseInt(data.id) == parseInt(viewProductRef.current.id) &&
                    window.location.href.includes('/productView')
                ) {
                    moveon_next_page()
                }
            }
            // console.log('real close update data reverse auction', data)
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socketAuction.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socketAuction.on('bidAddtime', (data) => {
            if (global.pluginConfiguration?.reverse_auction?.enable) {
                multiseller_handler(data, 'bidAddtime')
            } else {
                handler(data, 'bidAddtime')
            }
        })

        socket.on('make_an_offer_update', (data) => {
            make_an_offer_update_new(data, 'make_an_offer')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    data.id = data.pid
                }
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socketAuction.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socketAuction.off('bidAddtime', (data) => {
                if (global.pluginConfiguration?.reverse_auction?.enable) {
                    multiseller_handler(data, 'bidAddtime')
                } else {
                    handler(data, 'bidAddtime')
                }
            })

            socket.off('make_an_offer_update', (data) => {
                make_an_offer_update_new(data, 'make_an_offer')
            })
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const lotInformation = [
        {
            title: (
                <>
                    <span className="material-icons">description</span>
                    {storeTheme == 'LiquidationPallet' ? 'Product Description' : 'Auction Details'}
                </>
            ),
            description: selectedProduct?.description,
        },

        {
            title: (
                <>
                    <span className="material-icons">description</span>Download manifest
                </>
            ),
            description: selectedProduct?.documents?.map((val) => (
                <>
                    <a
                        href={`${process.env.REACT_APP_BASE_URL}uploads/${val}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {val}
                    </a>
                    <br />
                </>
            )),
            html: 1,
        },
    ]
    if (global.storeConfigration?.disable_contacts_field?.value != 1) {
        lotInformation.push({
            title:
                storeTheme == 'LiquidationPallet' ? (
                    <>
                        <span className="material-icons">local_shipping</span>Shipping Terms
                    </>
                ) : (
                    <>
                        <span className="material-icons">contacts</span>Contacts
                    </>
                ),
            description: selectedProduct?.custom_field_5,
        })
    }
    if (global.storeConfigration?.disable_tax_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">percent</span>Tax/Removal
                </>
            ),
            description: selectedProduct?.custom_field_4,
        })
    }
    if (global.storeConfigration?.disable_terms_field?.value != 1) {
        lotInformation.push({
            title: (
                <>
                    <span className="material-icons">privacy_tip</span>Terms & Conditions
                </>
            ),
            description: selectedProduct?.custom_field_7,
        })
    }

    //useEffect(() => {
    //     if (global.pluginConfiguration?.reverse_auction?.enable) {
    //         //&& selectedProduct.market_status != 'open'
    //         console.log('ooooooooooooooooooooooooooooooooooooooooooo1')
    //         if (lotDetails) {
    //             console.log('ooooooooooooooooooooooooooooooooooooooooooo2')
    //             lotDetails.map((val, index) => {
    //                 console.log('ooooooooooooooooooooooooooooooooooooooooooo3')
    //                 if (val.id == selectedProduct.id) {
    //                     console.log('ooooooooooooooooooooooooooooooooooooooooooo4')
    //                     if (lotDetails.length > parseInt(index) + 1) {
    //                         console.log(
    //                             'ooooooooooooooooooooooooooooooooooooooooooo5',
    //                             lotDetails[index + 1].id,
    //                         )
    //                         history.push({
    //                             pathname: `/productView/${lotDetails[index + 1].id}`,
    //                             state: { lotid: lotDetails },
    //                         })
    //                     }
    //                 }
    //             })
    //         }
    //     }
    // }, [selectedProduct])
    const moveon_next_page = () => {
        if (global.pluginConfiguration?.reverse_auction?.enable) {
            //&& selectedProduct.market_status != 'open'
            // console.log('ppppppppppppppppppppppppppppppppppp0')
            if (lotDetails) {
                lotDetails.map((val, index) => {
                    // console.log(
                    //     'ppppppppppppppppppppppppppppppppppp1',
                    //     val.id,
                    //     viewProductRef.current.id,
                    // )
                    if (parseInt(val.id) == parseInt(viewProductRef.current.id)) {
                        if (lotDetails.length > parseInt(index) + 1) {
                            // console.log('ppppppppppppppppppppppppppppppppppp2')
                            setAlert('Next Item Loaded Here.Please Continue To Bid.', 'success')
                            history.push({
                                pathname: `/productView/${lotDetails[index + 1].id}`,
                                state: { lotid: lotDetails },
                            })
                        }
                    }
                })
            }
        }
    }

    const validationmake_formik = Yup.object({
        proposed_amount: Yup.number()
            .required('Please Enter Value')
            .min(1, 'Amount should be greater than 1 or equal'),
    })

    const make_formik = useFormik({
        initialValues: {
            proposed_amount: '',
            project_id: '',
            user_id: '',
            seller_id: '',
            status: '',
        },
        validationSchema: validationmake_formik,
        onSubmit: async (values) => {
            make_formik.values.project_id = selectedProduct.id
            make_formik.values.user_id = user.id
            make_formik.values.seller_id = selectedProduct.user_id
            make_formik.values.status = 'Pending'
            insert_make_proposels(values)
            make_formik.values.proposed_amount = ''
        },
    })

    var make_an_offer = [
        {
            label: 'Make Offer',
            placeholder: 'Enter Price',
            type: 'number',
            class: 'col-sm-6 col-12',
            name: 'proposed_amount',
            formik: make_formik,
        },
    ]

    return (
        <>
            {loading ? (
                <div className="customContainer pt-3 auctionCnt">
                    <Loaders name="product_view" isLoading={loading} loop={1} />
                </div>
            ) : (
                <>
                    {Object.keys(selectedProduct).length !== 0 && (
                        <>
                            {isAuthenticated ? (
                                selectedProduct.auction ? (
                                    type === 'buynow' ? null : (
                                        <>
                                            <BidStatus
                                                bidTopStatus={selectedProduct.bidtopstatus}
                                            />
                                        </>
                                    )
                                ) : null
                            ) : null}
                            <div className="mpTop">
                                {isArtTheme && (
                                    <div
                                        className="goBackBTn"
                                        onClick={() => {
                                            history.push('/')
                                            window.scrollTo(0, 0)
                                        }}
                                    >
                                        <svg
                                            width="32"
                                            height="32"
                                            className="mr-2"
                                            viewBox="0 0 32 32"
                                        >
                                            <g transform="translate(-60 -71)">
                                                <g
                                                    transform="translate(60 71)"
                                                    fill="none"
                                                    stroke="#0a0a0a"
                                                    strokeWidth="1"
                                                >
                                                    <circle cx="16" cy="16" r="16" stroke="none" />
                                                    <circle cx="16" cy="16" r="15.5" fill="none" />
                                                </g>
                                                <path
                                                    d="M13.178,11.438a.664.664,0,0,1,.005.934L10.1,15.465H21.846a.66.66,0,0,1,0,1.32H10.1l3.087,3.092a.668.668,0,0,1-.005.934.657.657,0,0,1-.929-.005L8.07,16.592h0a.741.741,0,0,1-.137-.208.63.63,0,0,1-.051-.254.662.662,0,0,1,.188-.462l4.184-4.215A.647.647,0,0,1,13.178,11.438Z"
                                                    transform="translate(61.048 70.873)"
                                                />
                                            </g>
                                        </svg>
                                        <u>Back To Home</u>
                                    </div>
                                )}
                                {!isArtTheme && (
                                    <h3 className="plTitle">
                                        {storeTheme == 'LiquidationPallet' ? (
                                            <>
                                                <span>
                                                    <Link to="/">Home</Link> /{' '}
                                                    {global?.searchCategories?.length > 0 ? (
                                                        <Link
                                                            to={
                                                                '/search?cat=' +
                                                                selectedProduct.category_id
                                                            }
                                                        >
                                                            {
                                                                global?.searchCategories?.filter(
                                                                    (val) =>
                                                                        parseInt(val.id) ==
                                                                        parseInt(
                                                                            selectedProduct.category_id,
                                                                        ),
                                                                )[0]?.name
                                                            }
                                                        </Link>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </>
                                        ) : (
                                            <span>Auction name</span>
                                        )}

                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: selectedProduct.title,
                                            }}
                                        />
                                    </h3>
                                )}
                                {selectedProduct?.auction ? (
                                    <>
                                        {(selectedProduct.market_status != 'open' &&
                                            selectedProduct.market_status != 'draft') ||
                                        selectedProduct.productClosed ? (
                                            'Closed'
                                        ) : selectedProduct.extend || selectedProduct.agent_id ? (
                                            <span className="tmm">
                                                <Timer
                                                    date_added={selectedProduct.date_added}
                                                    date_closed={selectedProduct.date_closed}
                                                    withText={1}
                                                    endText={'Ends in:'}
                                                    startText={'Starts in:'}
                                                    theme="multiseller"
                                                ></Timer>
                                            </span>
                                        ) : (
                                            <span>
                                                {selectedProduct.market_status != 'draft' ? (
                                                    <Timer
                                                        date_added={selectedProduct.date_added}
                                                        date_closed={selectedProduct.date_closed}
                                                        withText={1}
                                                        endText={'TIME LEFT'}
                                                        startText={'Starts in'}
                                                        theme="multiseller"
                                                    ></Timer>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>

                            {theme === 'multiAuction' ? (
                                <>
                                    {selectedProduct.market_status == 'closed' ? (
                                        <h6 style={{ float: 'right', color: '#81daf3' }}>
                                            <span>Unsold</span>
                                        </h6>
                                    ) : selectedProduct.market_status == 'sold' ? (
                                        <h6 style={{ float: 'right', color: '#81daf3' }}>
                                            {`Sold Bid : ${currencyFormat(selectedProduct.wprice)}`}
                                        </h6>
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                            <div className="pvsCnt">
                                <div>
                                    <ImageGallery
                                        items={selectedProductImage}
                                        thumbnailPosition="bottom"
                                        showNav={false}
                                        showBullets={false}
                                        showFullscreenButton={true}
                                        showPlayButton={false}
                                        onErrorImageURL={`${global?.storeDetails?.logoValue}`}
                                        originalClass="slidImg img-fluid"
                                    />
                                    {isArtTheme && (
                                        <div className="similarArtSection">
                                            <div>
                                                {isArtTheme && (
                                                    <div className="followUp">
                                                        <div className="avatharInfo">
                                                            <div className="userCrd">
                                                                <figure className="userPic m-0">
                                                                    <img
                                                                        src={selectedProduct.avatar}
                                                                        onError={(e) =>
                                                                            (e.target.src =
                                                                                imageError())
                                                                        }
                                                                    />
                                                                </figure>
                                                                <div className="userIdenty">
                                                                    <h4 className="userTit">
                                                                        {selectedProduct.first_name}
                                                                        {selectedProduct.last_name}
                                                                    </h4>
                                                                    <p className="usrposi">
                                                                        {
                                                                            selectedProduct.company_name
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {followornot == 0 ? (
                                                                <PrimaryButton
                                                                    onClick={() =>
                                                                        userFollow(
                                                                            1,
                                                                            selectedProduct.user_id,
                                                                        )
                                                                    }
                                                                    className="followUp"
                                                                >
                                                                    <span className="material-icons mr-2">
                                                                        remove
                                                                    </span>
                                                                    Unfollow
                                                                </PrimaryButton>
                                                            ) : (
                                                                <PrimaryButton
                                                                    onClick={() =>
                                                                        userFollow(
                                                                            0,
                                                                            selectedProduct.user_id,
                                                                        )
                                                                    }
                                                                    className="followUp"
                                                                >
                                                                    <span className="material-icons mr-2">
                                                                        add
                                                                    </span>
                                                                    Follow
                                                                </PrimaryButton>
                                                            )}
                                                        </div>
                                                        <div className="avatharIntractive">
                                                            <PrimaryButton className="">
                                                                {selectedProduct.market_status ==
                                                                'open' ? (
                                                                    <FavouriteCheckbox
                                                                        watchlisted={
                                                                            selectedProduct.wlistpop
                                                                        }
                                                                        data={selectedProduct}
                                                                        project_id={
                                                                            selectedProduct.id
                                                                        }
                                                                        from={'card'}
                                                                        getAllProducts={() => {}}
                                                                        setAddFav={setFav}
                                                                        setSelectedProduct={
                                                                            setSelectedProduct
                                                                        }
                                                                    />
                                                                ) : null}

                                                                {addfav
                                                                    ? 'Remove from Favorites'
                                                                    : 'Add to Favorites'}
                                                            </PrimaryButton>
                                                            <SecondaryButton
                                                                aria-describedby={id}
                                                                onClick={SocialShareClick}
                                                            >
                                                                <span className="material-icons mr-2">
                                                                    share
                                                                </span>
                                                                Share
                                                            </SecondaryButton>
                                                            <Popover
                                                                id={popoverid}
                                                                open={openSocialShare}
                                                                anchorEl={openSocialShare}
                                                                onClose={SocialShareClock}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                            >
                                                                {/* <MenuItem onClick={handleClose}>
                                                                        FaceBook
                                                                    </MenuItem>
                                                                    <MenuItem onClick={handleClose}>
                                                                        My account
                                                                    </MenuItem>
                                                                    <MenuItem onClick={handleClose}>
                                                                        Logout
                                                                    </MenuItem> */}
                                                                <Facebook
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    title={selectedProduct.title}
                                                                >
                                                                    Facebook
                                                                </Facebook>
                                                                <Whatsapp
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    title={selectedProduct.title}
                                                                />
                                                                {global.storeConfigration
                                                                    ?.disable_twitter_sharing
                                                                    ?.value != 1 ? (
                                                                    <Twitter
                                                                        path={`/product/${selectedProduct.id}`}
                                                                        title={
                                                                            selectedProduct.title
                                                                        }
                                                                    />
                                                                ) : null}
                                                                <Email
                                                                    path={`/product/${selectedProduct.id}`}
                                                                    subject={selectedProduct.title}
                                                                    body={
                                                                        selectedProduct.description
                                                                    }
                                                                />
                                                                {global.storeConfigration
                                                                    ?.disable_pinterest_sharing
                                                                    ?.value != 1 ? (
                                                                    <Pinterest
                                                                        path={`/product/${selectedProduct.id}`}
                                                                        title={
                                                                            selectedProduct.title
                                                                        }
                                                                        media={
                                                                            `${
                                                                                selectedProduct.content_head1 ===
                                                                                '0'
                                                                                    ? process.env
                                                                                          .REACT_APP_BASE_URL +
                                                                                      'uploads/product/'
                                                                                    : process.env
                                                                                          .REACT_APP_PRODUCT_IMAGE_URL
                                                                            }` +
                                                                            selectedProduct.avatar
                                                                        }
                                                                    />
                                                                ) : null}
                                                                <div
                                                                    className="cpyToClipDiv"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <Copy
                                                                        text={`/productView/${selectedProduct.id}`}
                                                                    />
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <h4 className="mb-4">Other Artworks You May Like</h4>
                                            {loading ? (
                                                <div className="homeLoader">
                                                    <Loaders
                                                        name="product_grid_view"
                                                        isLoading={isLoading}
                                                        loop={9}
                                                    />
                                                </div>
                                            ) : viewProduct.filter(
                                                  (data) =>
                                                      data.market_status == 'open' &&
                                                      data.remainingDay >= 0,
                                              ).length !== 0 ? (
                                                <div
                                                    className={`homeAuctions ${
                                                        !isDesktop && 'mobileView'
                                                    }`}
                                                >
                                                    {viewProduct.map((data, index) => (
                                                        <>
                                                            {
                                                                <GridProduct
                                                                    data={data}
                                                                    className={
                                                                        data.auction
                                                                            ? 'auction'
                                                                            : 'buynow'
                                                                    }
                                                                    key={index}
                                                                    type={
                                                                        data.auction
                                                                            ? 'auction'
                                                                            : 'buynow'
                                                                    }
                                                                    selectedProduct={
                                                                        selectedProduct
                                                                    }
                                                                    link={`/gallery/product-view/${data.id}`}
                                                                    handleClick={handleClick}
                                                                    timerTheme="ArtAuction"
                                                                    sliderTheme="multiseller"
                                                                    cardTheme="ArtAuction"
                                                                    bidBtnLabel="Place Bid"
                                                                    viewProduct={viewProduct}
                                                                    setViewProduct={setViewProduct}
                                                                />
                                                            }
                                                            {console.log(
                                                                viewProduct,
                                                                'viewProduct',
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            ) : (
                                                <NoRecordsFound />
                                            )}
                                            {console.log(viewProduct, 'viewProduct')}
                                        </div>
                                    )}
                                </div>
                                {theme === 'multiAuction' ? (
                                    <div className="pvsRight multiAuctionProductView d-flex align-items-start justify-content-between">
                                        <div className="lftInfoContainer">
                                            <h5 className="lotNo colorPrimary">
                                                Lot#{' '}
                                                {selectedProduct.sku
                                                    ? selectedProduct.sku
                                                    : selectedProduct.deed_document
                                                    ? selectedProduct.deed_document
                                                    : selectedProduct.lot_number
                                                    ? selectedProduct.lot_number
                                                    : selectedProduct.id}
                                            </h5>
                                            <div
                                                className="dscrptnCntns"
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedProduct?.description,
                                                }}
                                            ></div>
                                            <div className="productInfo">
                                                {selectedProduct.market_status != 'open' ||
                                                selectedProduct.productClosed ? (
                                                    <h5>Auction Closed</h5>
                                                ) : (
                                                    <>
                                                        <h5>
                                                            <span>Auction Opens</span>
                                                            {moment(selectedProduct.date_added)
                                                                .tz(moment.tz.guess())
                                                                .format('MMM Do YYYY, h:mm a z')}
                                                        </h5>
                                                        <h5>
                                                            <span>Auction Closes</span>
                                                            {moment(selectedProduct.date_closed)
                                                                .tz(moment.tz.guess())
                                                                .format('MMM Do YYYY, h:mm a z')}
                                                        </h5>
                                                    </>
                                                )}
                                                {theme === 'multiAuction' ? (
                                                    <>
                                                        {(parseInt(selectedProduct?.lowest) > 0 ||
                                                            parseInt(selectedProduct?.highest) >
                                                                0) && (
                                                            <h5>
                                                                <span>Est: </span>
                                                                {currencyFormat(
                                                                    selectedProduct.lowest,
                                                                )}{' '}
                                                                -{' '}
                                                                {currencyFormat(
                                                                    selectedProduct.highest,
                                                                )}
                                                            </h5>
                                                        )}
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                <h5>
                                                    <span>Buyer&apos;s Premium</span>
                                                    {selectedProduct.market_status == 'sold' ? (
                                                        <>
                                                            {parseInt(
                                                                selectedProduct.highbid_user_id,
                                                            ) == parseInt(user?.id)
                                                                ? currencyFormat(
                                                                      parseFloat(
                                                                          parseFloat(
                                                                              (parseFloat(
                                                                                  selectedProduct.wprice,
                                                                              ) *
                                                                                  parseFloat(
                                                                                      selectedProduct.buyerpremium_percentage,
                                                                                  )) /
                                                                                  100,
                                                                          ).toFixed(2),
                                                                      ),
                                                                  )
                                                                : selectedProduct.buyerpremium_percentage
                                                                ? selectedProduct.buyerpremium_percentage +
                                                                  '%'
                                                                : 'N/A'}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {selectedProduct.buyerpremium_percentage
                                                                ? selectedProduct.buyerpremium_percentage +
                                                                  '%'
                                                                : 'N/A'}
                                                        </>
                                                    )}
                                                </h5>
                                                {/* <h5>
                                            <span>Product Location</span>
                                            {selectedProduct.city ? selectedProduct.city : 'N/A'}
                                        </h5> */}
                                                {selectedProduct.qty ? (
                                                    <h5>
                                                        <span>Quantity:</span>
                                                        {selectedProduct.qty}
                                                    </h5>
                                                ) : null}
                                                {theme === 'multiAuction' ? (
                                                    <>
                                                        <h5>
                                                            <span>
                                                                {parseInt(selectedProduct.bids) > 1
                                                                    ? 'Total Bids'
                                                                    : 'Total Bid'}
                                                            </span>
                                                            {selectedProduct.bids
                                                                ? selectedProduct.bids
                                                                : 0}
                                                        </h5>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                                {selectedProduct.selling_unit ? (
                                                    <h5>
                                                        <span>Unit:</span>
                                                        {
                                                            unitOptions.find(
                                                                (val) =>
                                                                    val.id ==
                                                                    selectedProduct.selling_unit,
                                                            ).description
                                                        }
                                                    </h5>
                                                ) : null}
                                                {selectedProduct.total_weight ? (
                                                    <div className="mssBidInfo">
                                                        <h5>
                                                            <span>Total Weight:</span>
                                                            {selectedProduct.total_weight}
                                                            {storeTheme === 'FoodIndustry'
                                                                ? 'Kg'
                                                                : ''}
                                                        </h5>
                                                    </div>
                                                ) : null}
                                                {selectedProduct.storage ? (
                                                    <h5>
                                                        <span>Storage:</span>
                                                        {
                                                            storageOptions.find(
                                                                (val) =>
                                                                    val.id ==
                                                                    selectedProduct.storage,
                                                            ).description
                                                        }
                                                    </h5>
                                                ) : null}

                                                {selectedProduct.conditionunique ? (
                                                    <h5>
                                                        <span>Condition:</span>
                                                        {allCondition
                                                            .filter(
                                                                (condition) =>
                                                                    condition.id ==
                                                                    selectedProduct.conditionunique,
                                                            )
                                                            .map((data) => data.description)}
                                                    </h5>
                                                ) : null}
                                                {selectedProduct.package_date ? (
                                                    <h5>
                                                        <span>Package Date:</span>
                                                        {selectedProduct.package_date}
                                                    </h5>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* {console.log('selectedProduct', selectedProduct)} */}
                                        <div className="rtInfoContainer">
                                            <div className="mssBidItem">
                                                {selectedProduct.market_status != 'open' ||
                                                selectedProduct.productClosed ? (
                                                    ''
                                                ) : (
                                                    <div className="sl-pbids slidView">
                                                        {isAuthenticated ? (
                                                            <>
                                                                {selectedProduct?.auction ? (
                                                                    type === 'buynow' ? null : (
                                                                        <Fragment>
                                                                            {selectedProduct.user_id ==
                                                                            user.id ? (
                                                                                <p>
                                                                                    You cannot bid
                                                                                    on your own
                                                                                    product!
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    <div className="crntPriceWrapper">
                                                                                        {selectedProduct.latestbid ? (
                                                                                            <>
                                                                                                <label>
                                                                                                    {selectedProduct.market_status ==
                                                                                                    'sold'
                                                                                                        ? 'Sold Bid'
                                                                                                        : 'Current Bid'}
                                                                                                </label>
                                                                                                <h5>
                                                                                                    <span></span>
                                                                                                    <span className="crncyValue">
                                                                                                        {currencyFormat(
                                                                                                            selectedProduct.wprice,
                                                                                                        )}
                                                                                                    </span>
                                                                                                </h5>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {selectedProduct.market_status ==
                                                                                                'closed' ? (
                                                                                                    <label>
                                                                                                        Unsold
                                                                                                    </label>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <label>
                                                                                                            Start
                                                                                                            Bid
                                                                                                        </label>
                                                                                                        <h5>
                                                                                                            {currencyFormat(
                                                                                                                selectedProduct.sprice,
                                                                                                            )}
                                                                                                        </h5>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="productInfo minBdInfo">
                                                                                        <label className="m-0 mr-2">
                                                                                            Minimum
                                                                                            Bid
                                                                                        </label>
                                                                                        <h5>
                                                                                            {currencyFormat(
                                                                                                selectedProduct.next_bid,
                                                                                            )}
                                                                                        </h5>
                                                                                    </div>
                                                                                    {global
                                                                                        .storeConfigration
                                                                                        ?.hard_bid
                                                                                        ?.value && (
                                                                                        <div className="bidDet mb-4">
                                                                                            {storeTheme !==
                                                                                                'ArtAuction' &&
                                                                                                theme !==
                                                                                                    'multiAuction' && (
                                                                                                    <p className="slidViewBtnLabel">
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                        Hard
                                                                                                        Bid
                                                                                                        -
                                                                                                        Bid
                                                                                                        on
                                                                                                        the
                                                                                                        current
                                                                                                        price.
                                                                                                    </p>
                                                                                                )}
                                                                                            {theme ===
                                                                                                'multiAuction' &&
                                                                                                parseInt(
                                                                                                    selectedProduct.content_head5,
                                                                                                ) ===
                                                                                                    0 && (
                                                                                                    <p className="slidViewBtnLabel">
                                                                                                        <span className="material-icons">
                                                                                                            info
                                                                                                        </span>
                                                                                                        Hard
                                                                                                        Bid
                                                                                                        -
                                                                                                        Bid
                                                                                                        on
                                                                                                        the
                                                                                                        current
                                                                                                        price.
                                                                                                    </p>
                                                                                                )}
                                                                                            {global
                                                                                                .pluginConfiguration
                                                                                                ?.reverse_auction
                                                                                                ?.enable ? (
                                                                                                <Biddingforwardandreverse
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="1"
                                                                                                />
                                                                                            ) : (
                                                                                                <Bidding
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="1"
                                                                                                    inputSize={
                                                                                                        storeTheme ===
                                                                                                        'ArtAuction'
                                                                                                            ? 'large'
                                                                                                            : 'small'
                                                                                                    }
                                                                                                    enableNextBid={
                                                                                                        storeTheme ===
                                                                                                        'ArtAuction'
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                    {theme ===
                                                                                    'multiAuction'
                                                                                        ? parseInt(
                                                                                              selectedProduct.content_head5,
                                                                                          ) === 0 &&
                                                                                          global
                                                                                              .storeConfigration
                                                                                              ?.proxy_bid
                                                                                              ?.value !=
                                                                                              0 && (
                                                                                              <div className="bidDet">
                                                                                                  <p className="slidViewBtnLabel">
                                                                                                      <span className="material-icons">
                                                                                                          info
                                                                                                      </span>
                                                                                                      Proxy
                                                                                                      Bid
                                                                                                      -
                                                                                                      Enter
                                                                                                      the
                                                                                                      maximum
                                                                                                      bid
                                                                                                      you
                                                                                                      would
                                                                                                      like
                                                                                                      to
                                                                                                      automatically
                                                                                                      bid
                                                                                                      to.
                                                                                                  </p>
                                                                                                  {global
                                                                                                      .pluginConfiguration
                                                                                                      ?.reverse_auction
                                                                                                      ?.enable ? (
                                                                                                      <Biddingforwardandreverse
                                                                                                          data={
                                                                                                              selectedProduct
                                                                                                          }
                                                                                                          hard="0"
                                                                                                          status={
                                                                                                              1
                                                                                                          }
                                                                                                      />
                                                                                                  ) : (
                                                                                                      <Bidding
                                                                                                          data={
                                                                                                              selectedProduct
                                                                                                          }
                                                                                                          hard="0"
                                                                                                          status={
                                                                                                              1
                                                                                                          }
                                                                                                      />
                                                                                                  )}
                                                                                              </div>
                                                                                          )
                                                                                        : global
                                                                                              .storeConfigration
                                                                                              ?.proxy_bid
                                                                                              ?.value !=
                                                                                              0 && (
                                                                                              <div className="bidDet">
                                                                                                  <p className="slidViewBtnLabel">
                                                                                                      <span className="material-icons">
                                                                                                          info
                                                                                                      </span>
                                                                                                      Proxy
                                                                                                      Bid
                                                                                                      -
                                                                                                      Enter
                                                                                                      the
                                                                                                      maximum
                                                                                                      bid
                                                                                                      you
                                                                                                      would
                                                                                                      like
                                                                                                      to
                                                                                                      automatically
                                                                                                      bid
                                                                                                      to.
                                                                                                  </p>
                                                                                                  {global
                                                                                                      .pluginConfiguration
                                                                                                      ?.reverse_auction
                                                                                                      ?.enable ? (
                                                                                                      <Biddingforwardandreverse
                                                                                                          data={
                                                                                                              selectedProduct
                                                                                                          }
                                                                                                          hard="0"
                                                                                                          status={
                                                                                                              1
                                                                                                          }
                                                                                                      />
                                                                                                  ) : (
                                                                                                      <Bidding
                                                                                                          data={
                                                                                                              selectedProduct
                                                                                                          }
                                                                                                          hard="0"
                                                                                                          status={
                                                                                                              1
                                                                                                          }
                                                                                                      />
                                                                                                  )}
                                                                                              </div>
                                                                                          )}
                                                                                </>
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                ) : null}

                                                                {(parseInt(
                                                                    selectedProduct.buynow,
                                                                ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 0) ||
                                                                (parseInt(selectedProduct.wprice) <=
                                                                    parseInt(
                                                                        selectedProduct.bprice,
                                                                    ) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 1) ? (
                                                                    <>
                                                                        <div className="sl-pbids slidView asd">
                                                                            <div className="row d-flex flex-nowrap">
                                                                                {selectedProduct.user_id ==
                                                                                user.id ? (
                                                                                    <p>
                                                                                        You cannot
                                                                                        buy your own
                                                                                        product!
                                                                                    </p>
                                                                                ) : (
                                                                                    <button
                                                                                        className="pink-btn pbtn-sm"
                                                                                        onClick={
                                                                                            handleClick
                                                                                        }
                                                                                        disabled={
                                                                                            disabled
                                                                                        }
                                                                                    >
                                                                                        {disabled ? (
                                                                                            'Added to cart'
                                                                                        ) : (
                                                                                            <span className="d-flex align-items-center justify-content-center">
                                                                                                Buy
                                                                                                Now
                                                                                                <span className="material-icons ml-2">
                                                                                                    shopping_cart
                                                                                                </span>
                                                                                            </span>
                                                                                        )}
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pvsRight">
                                        {/* <div className="d-flex justify-content-between align-items-center pvTitleContainer flex-wrap">
                                            <div className="pvMiscActions">
                                                <FavouriteCheckbox
                                                    watchlisted={selectedProduct.wlistpop}
                                                    project_id={selectedProduct.id}
                                                />
                                                <Facebook
                                                    path={`/product/${selectedProduct.id}`}
                                                    title={selectedProduct.title}
                                                />
                                                <Whatsapp
                                                    path={`/product/${selectedProduct.id}`}
                                                    title={selectedProduct.title}
                                                />
                                                <Twitter
                                                    path={`/product/${selectedProduct.id}`}
                                                    title={selectedProduct.title}
                                                />
                                                <Email
                                                    path={`/product/${selectedProduct.id}`}
                                                    subject={selectedProduct.title}
                                                    body={selectedProduct.description}
                                                />
                                                <Pinterest
                                                    path={`/product/${selectedProduct.id}`}
                                                    title={selectedProduct.title}
                                                    media={
                                                        `${
                                                            selectedProduct.content_head1 === '0'
                                                                ? process.env.REACT_APP_BASE_URL +
                                                                'uploads/product/'
                                                                : process.env.REACT_APP_PRODUCT_IMAGE_URL
                                                        }` + selectedProduct.avatar
                                                    }
                                                />
                                            </div>
                                        </div> */}
                                        {isArtTheme && (
                                            <h3 className="plTitle">
                                                {storeTheme == 'LiquidationPallet' ? (
                                                    <>
                                                        <span>
                                                            <Link to="/">Home</Link> /{' '}
                                                            {global?.searchCategories?.length >
                                                            0 ? (
                                                                <Link
                                                                    to={
                                                                        '/search?cat=' +
                                                                        selectedProduct.category_id
                                                                    }
                                                                >
                                                                    {
                                                                        global?.searchCategories?.filter(
                                                                            (val) =>
                                                                                parseInt(val.id) ==
                                                                                parseInt(
                                                                                    selectedProduct.category_id,
                                                                                ),
                                                                        )[0]?.name
                                                                    }
                                                                </Link>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </span>
                                                    </>
                                                ) : (
                                                    <span>Lot Name</span>
                                                )}

                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedProduct.title,
                                                    }}
                                                />
                                            </h3>
                                        )}
                                        {!isArtTheme && (
                                            <>
                                                <h5 className="lotNo">
                                                    Lot#{' '}
                                                    {selectedProduct.sku != '0' &&
                                                    electedProduct.sku != ''
                                                        ? selectedProduct.sku
                                                        : selectedProduct.lot_number
                                                        ? selectedProduct.lot_number
                                                        : selectedProduct.deed_document
                                                        ? selectedProduct.deed_document
                                                        : selectedProduct.id}
                                                </h5>
                                                {/* {console.log('selectedproduct', selectedProduct)} */}
                                                <div className="productInfo mt-4">
                                                    {selectedProduct?.auction ? (
                                                        <>
                                                            {selectedProduct.market_status !=
                                                                'open' ||
                                                            selectedProduct.productClosed ? (
                                                                <h5>Auction Closed</h5>
                                                            ) : (
                                                                <>
                                                                    {conditions ==
                                                                    'Collectibles' ? (
                                                                        <>
                                                                            <h5>
                                                                                <span>
                                                                                    Auction Opens
                                                                                </span>
                                                                                {moment(
                                                                                    selectedProduct.date_added,
                                                                                )
                                                                                    .tz(
                                                                                        moment.tz.guess(),
                                                                                    )
                                                                                    .format(
                                                                                        'MMM Do YYYY, h:mm a z',
                                                                                    )}
                                                                            </h5>
                                                                            <h5>
                                                                                <span>
                                                                                    Auction Closes
                                                                                </span>
                                                                                {moment(
                                                                                    selectedProduct.date_closed,
                                                                                )
                                                                                    .tz(
                                                                                        moment.tz.guess(),
                                                                                    )
                                                                                    .format(
                                                                                        'MMM Do YYYY, h:mm a z',
                                                                                    )}
                                                                            </h5>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <h5>
                                                                                <span>
                                                                                    Auction Opens
                                                                                </span>
                                                                                {moment(
                                                                                    selectedProduct.date_added,
                                                                                )
                                                                                    .tz(
                                                                                        'America/New_York',
                                                                                    )
                                                                                    .format(
                                                                                        'MMM Do YYYY, h:mm a',
                                                                                    )}
                                                                            </h5>
                                                                            <h5>
                                                                                <span>
                                                                                    Auction Closes
                                                                                </span>
                                                                                {moment(
                                                                                    selectedProduct.date_closed,
                                                                                )
                                                                                    .tz(
                                                                                        'America/New_York',
                                                                                    )
                                                                                    .format(
                                                                                        'MMM Do YYYY, h:mm a',
                                                                                    )}
                                                                            </h5>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {storeTheme == 'LiquidationPallet' ? (
                                                        <h5>
                                                            <span>Location:</span>
                                                            {selectedProduct.city +
                                                                ', ' +
                                                                selectedProduct.state}
                                                        </h5>
                                                    ) : (
                                                        <h5>
                                                            <span>Product Location</span>
                                                            {selectedProduct.city}
                                                        </h5>
                                                    )}

                                                    {selectedProduct.qty ? (
                                                        <h5>
                                                            <span>Quantity:</span>
                                                            {selectedProduct.qty}
                                                        </h5>
                                                    ) : null}
                                                    {selectedProduct.selling_unit ? (
                                                        <h5>
                                                            <span>Unit:</span>
                                                            {
                                                                unitOptions.find(
                                                                    (val) =>
                                                                        val.id ==
                                                                        selectedProduct.selling_unit,
                                                                ).description
                                                            }
                                                        </h5>
                                                    ) : null}
                                                    {selectedProduct.total_weight ? (
                                                        <div className="mssBidInfo">
                                                            <h5>
                                                                <span>Total Weight:</span>
                                                                {selectedProduct.total_weight}
                                                                {storeTheme === 'FoodIndustry'
                                                                    ? 'Kg'
                                                                    : ''}
                                                            </h5>
                                                        </div>
                                                    ) : null}
                                                    {selectedProduct.storage ? (
                                                        <h5>
                                                            <span>Storage:</span>
                                                            {
                                                                storageOptions.find(
                                                                    (val) =>
                                                                        val.id ==
                                                                        selectedProduct.storage,
                                                                ).description
                                                            }
                                                        </h5>
                                                    ) : null}

                                                    {selectedProduct.conditionunique ? (
                                                        <>
                                                            {conditions === 'Collectibles' ? (
                                                                <h5>
                                                                    <span>Condition:</span>
                                                                    {collectibleConditions
                                                                        .filter(
                                                                            (condition) =>
                                                                                condition.id ==
                                                                                selectedProduct.conditionunique,
                                                                        )
                                                                        .map(
                                                                            (data) =>
                                                                                data.description,
                                                                        )}
                                                                </h5>
                                                            ) : (
                                                                <h5>
                                                                    <span>Condition:</span>
                                                                    {allCondition
                                                                        .filter(
                                                                            (condition) =>
                                                                                condition.id ==
                                                                                selectedProduct.conditionunique,
                                                                        )
                                                                        .map(
                                                                            (data) =>
                                                                                data.description,
                                                                        )}
                                                                </h5>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {storeTheme == 'LiquidationPallet' && (
                                                        <>
                                                            {selectedProduct.content_head5 ? (
                                                                <>
                                                                    <h5>
                                                                        <span>Seller:</span>
                                                                        {brandsliqutatios
                                                                            .filter((condition) =>
                                                                                selectedProduct.content_head5
                                                                                    .split(',')
                                                                                    .includes(
                                                                                        `${condition.id}`,
                                                                                    ),
                                                                            )

                                                                            .map(
                                                                                (data) =>
                                                                                    data.description,
                                                                            )
                                                                            .join(', ')}
                                                                    </h5>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    )}
                                                    {selectedProduct.package_date ? (
                                                        <h5>
                                                            <span>Package Date:</span>
                                                            {selectedProduct.package_date}
                                                        </h5>
                                                    ) : null}
                                                </div>
                                            </>
                                        )}
                                        {isArtTheme ? (
                                            <>
                                                <div className="ArtProviewBiddingSection">
                                                    {selectedProduct?.auction && (
                                                        <div className="AuctionTytpe">
                                                            <h4 className="bidingTypeTit">
                                                                Current Bid
                                                            </h4>
                                                            <span className="BidingAmt">
                                                                {currencyFormat(
                                                                    selectedProduct.wprice,
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mssBidItem ArtbidderChanges">
                                                    {selectedProduct.market_status != 'open' ||
                                                    selectedProduct.productClosed ? (
                                                        ''
                                                    ) : (
                                                        <div className="sl-pbids slidView">
                                                            {isAuthenticated ? (
                                                                <>
                                                                    {selectedProduct.auction ? (
                                                                        type === 'buynow' ? null : (
                                                                            <Fragment>
                                                                                {selectedProduct.user_id ==
                                                                                user.id ? (
                                                                                    <p>
                                                                                        You cannot
                                                                                        bid on your
                                                                                        own product!
                                                                                    </p>
                                                                                ) : (
                                                                                    <>
                                                                                        <div className="artBiddingFlex">
                                                                                            <div>
                                                                                                <Bidding
                                                                                                    data={
                                                                                                        selectedProduct
                                                                                                    }
                                                                                                    hard="1"
                                                                                                />
                                                                                                <p className="bidderInfo">
                                                                                                    Bid
                                                                                                    $
                                                                                                    {selectedProduct?.next_bid
                                                                                                        ? selectedProduct?.next_bid
                                                                                                        : '-'}
                                                                                                    &nbsp;
                                                                                                    or
                                                                                                    more
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="ml-2">
                                                                                                {selectedProduct.auction &&
                                                                                                type !==
                                                                                                    'buynow' ? (
                                                                                                    <Button
                                                                                                        className="mssBidHistory"
                                                                                                        onClick={() =>
                                                                                                            (selectedProduct.bids &&
                                                                                                                selectedProduct.bids !==
                                                                                                                    0 &&
                                                                                                                !selectedProduct.is_past) ||
                                                                                                            isArtTheme
                                                                                                                ? setHistoryData(
                                                                                                                      {
                                                                                                                          product_id:
                                                                                                                              selectedProduct.id,
                                                                                                                          end: selectedProduct.date_closed,
                                                                                                                      },
                                                                                                                  )
                                                                                                                : ''
                                                                                                        }
                                                                                                    >
                                                                                                        <span className="material-icons">
                                                                                                            history
                                                                                                        </span>
                                                                                                        View
                                                                                                        Bid
                                                                                                        History
                                                                                                    </Button>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <Bidding
                                                                                            data={
                                                                                                selectedProduct
                                                                                            }
                                                                                            hard="0"
                                                                                        /> */}
                                                                                    </>
                                                                                )}
                                                                            </Fragment>
                                                                        )
                                                                    ) : null}
                                                                    {theme != 'multiAuction' ||
                                                                        (!isArtTheme && (
                                                                            <>
                                                                                {selectedProduct.auction &&
                                                                                type !==
                                                                                    'buynow' ? (
                                                                                    <Button
                                                                                        className="mssBidHistory"
                                                                                        onClick={() =>
                                                                                            selectedProduct.bids &&
                                                                                            selectedProduct.bids !==
                                                                                                0 &&
                                                                                            !selectedProduct.is_past &&
                                                                                            setHistoryData(
                                                                                                {
                                                                                                    product_id:
                                                                                                        selectedProduct.id,
                                                                                                    end: selectedProduct.date_closed,
                                                                                                },
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <span className="material-icons">
                                                                                            history
                                                                                        </span>
                                                                                        View bid
                                                                                        history
                                                                                    </Button>
                                                                                ) : null}
                                                                            </>
                                                                        ))}

                                                                    {(parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                        parseInt(
                                                                            selectedProduct.auction,
                                                                        ) == 0) ||
                                                                    (parseInt(
                                                                        selectedProduct.wprice,
                                                                    ) <=
                                                                        parseInt(
                                                                            selectedProduct.bprice,
                                                                        ) &&
                                                                        parseInt(
                                                                            selectedProduct.buynow,
                                                                        ) == 1 &&
                                                                        parseInt(
                                                                            selectedProduct.auction,
                                                                        ) == 1) ? (
                                                                        <>
                                                                            <div className="sl-pbids slidView">
                                                                                <div className="row d-flex flex-nowrap">
                                                                                    {selectedProduct.user_id ==
                                                                                    user.id ? (
                                                                                        <p>
                                                                                            You
                                                                                            cannot
                                                                                            buy your
                                                                                            own
                                                                                            product!
                                                                                        </p>
                                                                                    ) : (
                                                                                        <button
                                                                                            className="pink-btn pbtn-sm"
                                                                                            onClick={
                                                                                                selectedProduct.market_status ==
                                                                                                'open'
                                                                                                    ? handleClick
                                                                                                    : ''
                                                                                            }
                                                                                            disabled={
                                                                                                disabled
                                                                                            }
                                                                                        >
                                                                                            {disabled ? (
                                                                                                'Added to cart'
                                                                                            ) : (
                                                                                                <span className="d-flex align-items-center justify-content-center">
                                                                                                    Buy
                                                                                                    Now
                                                                                                    <span className="material-icons ml-2">
                                                                                                        shopping_cart
                                                                                                    </span>
                                                                                                </span>
                                                                                            )}
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <br />
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="mssBidItem">
                                                <div className="productInfo">
                                                    {selectedProduct?.auction ? (
                                                        <>
                                                            <h5>
                                                                <span>Current Bid</span>
                                                                {currencyFormat(
                                                                    selectedProduct.wprice,
                                                                )}
                                                            </h5>
                                                            <h5>
                                                                <span>Minimum Bid</span>
                                                                {currencyFormat(
                                                                    selectedProduct.next_bid,
                                                                )}
                                                            </h5>
                                                            {selectedProduct.buynow ? (
                                                                <h5>
                                                                    <span>Buy Now Price</span>
                                                                    {currencyFormat(
                                                                        selectedProduct.bprice,
                                                                    )}
                                                                </h5>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h5>
                                                                <span>Buy Now Price</span>
                                                                {currencyFormat(
                                                                    selectedProduct.bprice,
                                                                )}
                                                            </h5>
                                                        </>
                                                    )}
                                                </div>
                                                {selectedProduct.market_status != 'open' ||
                                                selectedProduct.productClosed ? (
                                                    ''
                                                ) : (
                                                    <div className="sl-pbids slidView">
                                                        {isAuthenticated ? (
                                                            <>
                                                                {selectedProduct.auction ? (
                                                                    type === 'buynow' ? null : (
                                                                        <Fragment>
                                                                            {selectedProduct.user_id ==
                                                                            user.id ? (
                                                                                <p>
                                                                                    You cannot bid
                                                                                    on your own
                                                                                    product!
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    <Bidding
                                                                                        data={
                                                                                            selectedProduct
                                                                                        }
                                                                                        hard="1"
                                                                                    />
                                                                                    <Bidding
                                                                                        data={
                                                                                            selectedProduct
                                                                                        }
                                                                                        hard="0"
                                                                                    />
                                                                                </>
                                                                            )}
                                                                        </Fragment>
                                                                    )
                                                                ) : null}
                                                                {storeTheme === 'FoodIndustry' && (
                                                                    <p
                                                                        className="howBidLink"
                                                                        onClick={() =>
                                                                            setHowBidPopup(true)
                                                                        }
                                                                    >
                                                                        How Does Bidding Work?
                                                                    </p>
                                                                )}
                                                                {theme != 'multiAuction' && (
                                                                    <>
                                                                        {selectedProduct.auction &&
                                                                        type !== 'buynow' ? (
                                                                            <Button
                                                                                className="mssBidHistory"
                                                                                onClick={() =>
                                                                                    selectedProduct.bids &&
                                                                                    selectedProduct.bids !==
                                                                                        0 &&
                                                                                    !selectedProduct.is_past &&
                                                                                    setHistoryData({
                                                                                        product_id:
                                                                                            selectedProduct.id,
                                                                                        end: selectedProduct.date_closed,
                                                                                    })
                                                                                }
                                                                            >
                                                                                <span className="material-icons">
                                                                                    history
                                                                                </span>
                                                                                View bid history
                                                                            </Button>
                                                                        ) : null}
                                                                    </>
                                                                )}

                                                                {(parseInt(
                                                                    selectedProduct.buynow,
                                                                ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 0) ||
                                                                (parseInt(selectedProduct.wprice) <=
                                                                    parseInt(
                                                                        selectedProduct.bprice,
                                                                    ) &&
                                                                    parseInt(
                                                                        selectedProduct.buynow,
                                                                    ) == 1 &&
                                                                    parseInt(
                                                                        selectedProduct.auction,
                                                                    ) == 1) ? (
                                                                    <>
                                                                        <div className="sl-pbids slidView sdsd">
                                                                            <div className="row d-flex flex-nowrap">
                                                                                {selectedProduct.user_id ==
                                                                                user.id ? (
                                                                                    <p>
                                                                                        You cannot
                                                                                        buy your own
                                                                                        product!
                                                                                    </p>
                                                                                ) : (
                                                                                    <button
                                                                                        className="pink-btn pbtn-sm"
                                                                                        onClick={
                                                                                            selectedProduct.market_status ==
                                                                                            'open'
                                                                                                ? handleClick
                                                                                                : ''
                                                                                        }
                                                                                        disabled={
                                                                                            disabled
                                                                                        }
                                                                                    >
                                                                                        {disabled ? (
                                                                                            'Added to cart'
                                                                                        ) : (
                                                                                            <span className="d-flex align-items-center justify-content-center">
                                                                                                Buy
                                                                                                Now
                                                                                                <span className="material-icons ml-2">
                                                                                                    shopping_cart
                                                                                                </span>
                                                                                            </span>
                                                                                        )}
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                ) : null}

                                                                {global.pluginConfiguration
                                                                    ?.make_an_offer?.enable &&
                                                                selectedProduct?.make_an_offer_io &&
                                                                selectedProduct?.user_id !=
                                                                    user?.id ? (
                                                                    <div className="row">
                                                                        {mapData(make_an_offer)}
                                                                        <div className="col-sm-6 col-12">
                                                                            <PrimaryButton
                                                                                onClick={(e) =>
                                                                                    selectedProduct.market_status ==
                                                                                    'open'
                                                                                        ? make_formik.handleSubmit()
                                                                                        : ''
                                                                                }
                                                                                disabled={
                                                                                    selectedProduct.market_status !=
                                                                                    'open'
                                                                                }
                                                                            >
                                                                                {selectedProduct.market_status ==
                                                                                'sold'
                                                                                    ? 'Lot Sold'
                                                                                    : selectedProduct.market_status ==
                                                                                          'unsold' ||
                                                                                      selectedProduct.market_status ==
                                                                                          'closed'
                                                                                    ? 'Lot Closed'
                                                                                    : `Submit Offer`}
                                                                            </PrimaryButton>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {isArtTheme && (
                                            <div className="ProductViewArtThemeAuctionDetails">
                                                <div className="ActionDecription">
                                                    <h4>Description</h4>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: lotInformation[0].description,
                                                        }}
                                                    ></div>
                                                </div>
                                                <div className="ArtProductInformation">
                                                    {console.log(
                                                        selectedProduct,
                                                        'selectedProduct',
                                                    )}
                                                    <Accordion
                                                        expanded={expanded === 'panel1'}
                                                        onChange={AccordionHandleChange('panel1')}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Product Detail</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul className="m-0 accordionUlist">
                                                                {productcategory ? (
                                                                    <li>
                                                                        <span>Category : </span>
                                                                        {productcategory}
                                                                    </li>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {productsubcategory ? (
                                                                    <li>
                                                                        <span>SubCategory : </span>
                                                                        {productsubcategory}
                                                                    </li>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                <li>
                                                                    <span>Width : </span>{' '}
                                                                    {selectedProduct?.width}{' '}
                                                                    (Inches)
                                                                </li>
                                                                <li>
                                                                    <span>Height : </span>{' '}
                                                                    {selectedProduct?.height}{' '}
                                                                    (Inches)
                                                                </li>
                                                                <li>
                                                                    <span>Length : </span>{' '}
                                                                    {selectedProduct?.length}{' '}
                                                                    (Inches)
                                                                </li>
                                                                <li>
                                                                    <span>Weight : </span>{' '}
                                                                    {selectedProduct?.weight}{' '}
                                                                    (Pounds)
                                                                </li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel2'}
                                                        onChange={AccordionHandleChange('panel2')}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2a-content"
                                                            id="panel2a-header"
                                                        >
                                                            <Typography>Payments</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul className="m-0 accordionUlist">
                                                                <li>Credit Cards</li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel3'}
                                                        onChange={AccordionHandleChange('panel3')}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel3a-content"
                                                            id="panel3a-header"
                                                        >
                                                            <Typography>
                                                                Shipping Details
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul className="m-0 accordionUlist">
                                                                <li>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: selectedProduct?.extbidding,
                                                                        }}
                                                                    ></div>
                                                                </li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion
                                                        expanded={expanded === 'panel4'}
                                                        onChange={AccordionHandleChange('panel4')}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel4a-content"
                                                            id="panel4a-header"
                                                        >
                                                            <Typography>Condition</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <ul className="m-0 accordionUlist">
                                                                <li>
                                                                    {allCondition
                                                                        .filter(
                                                                            (condition) =>
                                                                                condition.id ==
                                                                                selectedProduct?.conditionunique,
                                                                        )
                                                                        .map(
                                                                            (data) =>
                                                                                data.description,
                                                                        )}
                                                                </li>
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    {/* <Accordion
                                                        square
                                                        expanded={expanded === `panel${index}`}
                                                        key={1}
                                                        onChange={handleChange(`panel${index}`)}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index}}d-content`}
                                                            id={`panel${index}}d-header`}
                                                        >
                                                            <h6 className="accTitle">
                                                                {selectedProduct.title}
                                                            </h6>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <p
                                                                className="dscrpnCtnrArt"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: selectedProduct.description,
                                                                }}
                                                            ></p>
                                                        </AccordionDetails>
                                                    </Accordion> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            {theme != 'multiAuction' ||
                                (isArtTheme && (
                                    <div className="pvsBottom">
                                        {lotInformation && Object.keys(lotInformation).length ? (
                                            <>
                                                <AppBar position="static">
                                                    <Tabs
                                                        value={value}
                                                        variant={
                                                            matches ? 'scrollable' : 'fullWidth'
                                                        }
                                                        onChange={handleChange}
                                                        scrollButtons="auto"
                                                        aria-label="scrollable auto tabs example"
                                                    >
                                                        {lotInformation.map((data, index) => (
                                                            <Tab
                                                                label={data.title}
                                                                {...a11yProps({ index })}
                                                            />
                                                        ))}
                                                    </Tabs>
                                                </AppBar>
                                                {lotInformation.map((data, index) => (
                                                    <TabPanel
                                                        value={value}
                                                        index={index}
                                                        key={index}
                                                    >
                                                        {data.html ? (
                                                            data.description
                                                        ) : (
                                                            <div
                                                                className="content"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: data.description,
                                                                }}
                                                            />
                                                        )}
                                                    </TabPanel>
                                                ))}
                                            </>
                                        ) : null}

                                        {/* {lotInformation.map((data, index) => (
                            <Accordion
                                square
                                expanded={expanded === `panel${index}`}
                                key={1}
                                onChange={handleChange(`panel${index}`)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}}d-content`}
                                    id={`panel${index}}d-header`}
                                >
                                    <h6 className="accTitle">{data.title}</h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: data.description,
                                        }}
                                    ></p>
                                </AccordionDetails>
                            </Accordion>
                        ))} */}
                                    </div>
                                ))}
                            <Bidhistory
                                modaltitle="Bid history"
                                open={Object.keys(historyData).length !== 0}
                                value={historyData}
                                handleClose={() => setHistoryData({})}
                            />
                            <HowBidPopup
                                open={howBidPopup}
                                handleClose={() => setHowBidPopup(false)}
                            />
                            {global.pluginConfiguration?.message_plugin?.enable == 1 ? (
                                <MessagePlugin />
                            ) : null}
                        </>
                    )}
                </>
            )}
            {view_more_need ? (
                <SimilarListComponent
                    loading={loaded_item}
                    searchResult={similaritem}
                    selected_cat={selectedProduct.category_id}
                    selected_lotof={selectedProduct.lotof}
                    totalProductCount={totalSimilarItem}
                ></SimilarListComponent>
            ) : (
                ''
            )}
        </>
    )
}

export default Standalone
