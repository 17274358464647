import axios from 'axios'
import React from 'react'
import { Link } from 'react-router-dom'
import { swapCountry } from './commonFunctions'
import { useTranslation } from 'react-i18next'

export const siteLogo = ''

export const Copyrightinfo = () => {
    const { t } = useTranslation()
    return (
        <p className="text-center copyright">
            © {new Date().getFullYear()}{' '}
            {global?.pluginConfiguration?.language_manager?.enable == 1 ? (
                <span className="fc-name">{t('site_launch')}</span>
            ) : (
                <>
                    <span className="fc-name copy-right">{global?.storeDetails?.name}</span>. All
                    rights reserved.
                </>
            )}
        </p>
    )
}

export const Logo = (props) => (
    <>
        {global.storeConfigration?.logo_url?.value ? (
            <a className="nav-standard-logo" href={global.storeConfigration?.logo_url?.value}>
                <img
                    src={global?.storeDetails?.logoValue}
                    className={props.className}
                    alt={`${global?.storeDetails?.name} logo`}
                />
            </a>
        ) : (
            <Link className="nav-standard-logo" to={'/'}>
                <img
                    src={global?.storeDetails?.logoValue}
                    className={props.className}
                    alt={`${global?.storeDetails?.name} logo`}
                />
            </Link>
        )}
    </>
)

export const FooterLogo = (props) => (
    <Link className="nav-standard-logo" to="/">
        <img
            src={global?.storeDetails?.footerLogoValue}
            className={props.className}
            alt={`${global?.storeDetails?.name} logo`}
        />
    </Link>
)

export const allCondition = [
    {
        id: 1,
        description: 'Great',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 2,
        description: 'Good',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 3,
        description: 'Fair',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 4,
        description: 'Scrap',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 5,
        description: 'New',
        active: 1,
        ratingWeight: 10,
    },
    {
        id: 6,
        description: 'Used',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 7,
        description: 'Test 1',
        active: 0,
        ratingWeight: 0,
    },
    {
        id: 8,
        description: 'Test 2',
        active: 0,
        ratingWeight: 0,
    },
    {
        id: 9,
        description: 'New Condition Test ',
        active: 2,
        ratingWeight: 0,
    },
    {
        id: 10,
        description: 'Test New Conditions 123',
        active: 0,
        ratingWeight: 0,
    },
]

export const storageOptions = [
    {
        id: 1,
        description: 'Dry',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 2,
        description: 'Keep Cool',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 3,
        description: 'Keep Frozen',
        active: 1,
        ratingWeight: 0,
    },
]

export const unitOptions = [
    {
        id: 1,
        description: 'Pallet',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 2,
        description: 'Boxes',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 3,
        description: 'Totes',
        active: 1,
        ratingWeight: 0,
    },
]

export const sustainabilityOptions = [
    {
        id: 1,
        description: 'Oceanwise',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 2,
        description: 'MSC',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 3,
        description: 'Wild Caught',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 4,
        description: 'Grass Fed',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 5,
        description: 'Free Range',
        active: 1,
        ratingWeight: 10,
    },
    {
        id: 6,
        description: 'Organic',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 7,
        description: 'Naturally Raised',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 8,
        description: 'ABF',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 9,
        description: 'GMO Free',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 10,
        description: 'Open Barn Raised',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 11,
        description: 'Regenerative Practice',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 12,
        description: 'Other',
        active: 1,
        ratingWeight: 0,
    },
]

export const brandsliqutatios = [
    {
        id: 1,
        description: 'Amazon',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 2,
        description: 'Target',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 3,
        description: 'Titan',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 4,
        description: 'Wayfair',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 5,
        description: 'Home Depot',
        active: 1,
        ratingWeight: 10,
    },
    {
        id: 6,
        description: 'Sony',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 7,
        description: 'Bed Bath & Beyond',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 8,
        description: 'Hanes Brands',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 9,
        description: 'Ferguson',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 10,
        description: 'Build.com',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 11,
        description: 'Lowes',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 12,
        description: 'Almo',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 13,
        description: 'Gildan',
        active: 1,
        ratingWeight: 0,
    },
]

export const horseNonProfit = [
    {
        show: 'Yes',
        value: 'Yes',
    },
    {
        show: 'No',
        value: 'No',
    },
]
export const horseTypes = [
    {
        show: 'Stallion Share',
        value: 'Stallion Share',
    },
    {
        show: 'Stallion',
        value: 'Stallion',
    },
]

export const horseAges_cal = [...Array(30).keys()].map((i) => ({
    show: `${i === 0 ? 'Weanling' : i}${i == 0 ? '' : i == 1 ? ' Year' : ' Years'}`,
    value: `${i === 0 ? 'Weanling' : i}${i == 0 ? '' : i == 1 ? ' Year' : ' Years'}`,
}))

export const horseAges = [{ show: 'N/A or Embryo', value: 'N/A (or) Embryo' }, ...horseAges_cal]

export const horseGait = [
    {
        show: 'Pace',
        value: 'Pace',
    },
    {
        show: 'Trot',
        value: 'Trot',
    },
]

export const horseGender = [
    {
        show: 'Embryo',
        value: 'Embryo',
    },
    {
        show: 'Colt',
        value: 'Colt',
    },
    {
        show: 'Filly',
        value: 'Filly',
    },
    {
        show: 'Stallion',
        value: 'Stallion',
    },
    {
        show: 'Mare',
        value: 'Mare',
    },
    {
        show: 'Gelding',
        value: 'Gelding',
    },
]

export const horseColor = [
    {
        show: 'Embryo',
        value: 'Embryo',
    },
    {
        show: 'Chestnut',
        value: 'Chestnut',
    },
    {
        show: 'Sorrel',
        value: 'Sorrel',
    },
    {
        show: 'Bay',
        value: 'Bay',
    },
    {
        show: 'Buckskin',
        value: 'Buckskin',
    },
    {
        show: 'Palomino',
        value: 'Palomino',
    },
    {
        show: 'Dun',
        value: 'Dun',
    },
    {
        show: 'Black',
        value: 'Black',
    },
    {
        show: 'Grey',
        value: 'Grey',
    },
    {
        show: 'Red Roan',
        value: 'Red Roan',
    },
    {
        show: 'Red Dun',
        value: 'Red Dun',
    },
    {
        show: 'Blue Roan',
        value: 'Blue Roan',
    },
    {
        show: 'Brown',
        value: 'Brown',
    },
    {
        show: 'Bay Roan',
        value: 'Bay Roan',
    },
]
export const getLocation = async (countryCode, setState) => {
    try {
        let data = []
        const res = await axios.post(`${process.env.REACT_APP_URL}fetchLocation`, { countryCode })
        if (res.data.success) {
            if (countryCode) {
                data = res.data.state.map((val) => ({
                    show: val.name,
                    value: val.name,
                }))
            } else {
                data = swapCountry(res.data.country).map((val) => ({
                    show: val.name,
                    value: val.location_id,
                }))
            }

            if (setState) {
                return setState(data)
            }
        }
        return data
    } catch (error) {
        //console.log('comon fetch location error =>', error)
        if (setState) {
            return setState(data)
        }
        return []
    }
}

export const collectibleConditions = [
    {
        id: 1,
        description: 'Mint',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 2,
        description: 'Near Mint',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 3,
        description: 'Excellent',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 4,
        description: 'Very Good',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 5,
        description: 'Good',
        active: 1,
        ratingWeight: 10,
    },
    {
        id: 6,
        description: 'Poor',
        active: 1,
        ratingWeight: 0,
    },
    {
        id: 7,
        description: 'Mixed',
        active: 1,
        ratingWeight: 0,
    },
]

export const shippingStatus = [
    {
        show: 'Awaiting Shipment',
        id: 'Awaiting Shipment',
    },
    {
        show: 'Shipped',
        id: 'Shipped',
    },
    {
        show: 'Onhold',
        id: 'Onhold',
    },
    {
        show: 'Cancel Shipping',
        id: 'Cancel Shipping',
    },
]
export const carrierName = [
    {
        show: 'USPS',
        id: 'USPS',
    },
    {
        show: 'UPS',
        id: 'UPS',
    },
    {
        show: 'Fed-Ex',
        id: 'Fed-Ex',
    },
    {
        show: 'DHL',
        id: 'DHL',
    },
    {
        show: 'In Person Delivery',
        id: 'In Person Delivery',
    },
]

export const disputeSubject = [
    {
        show: 'Item Not Described/Incorrect item',
        id: 'Item Not Described/Incorrect item',
    },
    {
        show: 'Item not received',
        id: 'Item not received',
    },

    {
        show: 'Damaged Item',
        id: 'Damaged Item',
    },
]
