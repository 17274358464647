import React, { useState, useContext, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import './style/stripe.css'
import {
    CardElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import AlertContext from '../../../Product/context/alert/alertContext'
import DirectStripeContext from '../../../Product/context/directStripe/directStripeContext'
import AuthContext from '../../../Product/context/auth/authContext'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
}
const AddCardForm = ({ closePopup }) => {
    const { addNewCard, getStripeCard, responseStatus, clearResponse } =
        useContext(DirectStripeContext)
    const { user, loadUser } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)

    const [error, setError] = useState(null)
    const [firstName, setFirstName] = useState('')
    const [err, setErr] = useState(false)
    const [loader, setLoader] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const handleChange = (event) => {
        if (event.error) {
            setError(event.error.message)
        } else {
            setError(null)
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (firstName === '') return setErr(true)
        setLoader(true)
        const card = elements.getElement(CardElement)
        const result = await stripe.createToken(card)
        if (result.error) {
            setLoader(false)
            setError(result.error.message)
        } else {
            setError(null)
            addNewCard({
                token_id: result.token.id,
                first_name: firstName,
                user_id: user?.id,
                site_id: global.storeDetails.site_id,
            })
        }
    }
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addNewCard') {
                if (responseStatus.status === 'error') {
                    setLoader(false)
                    setAlert(responseStatus.message, 'error')
                }
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message, 'success')
                    if (closePopup) {
                        closePopup()
                    }

                    setLoader(false)
                    clearResponse()
                    getStripeCard({ user_id: user?.id, site_id: global.storeDetails.site_id })
                    if (!user?.card_paymentid) {
                        loadUser()
                    }
                }
            }
        }
    }, [responseStatus])
    return (
        <form className="w-100" onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="w-100">
                    <label className="lato" htmlFor="card-element">
                        Credit or debit card
                    </label>
                    <CardElement
                        id="card-element"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleChange}
                    />
                    <div className="card-errors" role="alert">
                        {error}
                    </div>
                </div>
                <div className="w-100">
                    <label className="lato mt-3" htmlFor="card-element">
                        Card Holder Name
                    </label>
                    <input
                        className="cstmInput InputElement"
                        type="text"
                        placeholder="Enter card holder name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className="card-errors" role="alert">
                        {err ? (firstName === '' ? 'Card holder name is required' : '') : null}
                    </div>
                </div>
            </div>
            <button type="submit" disabled={loader}>
                {loader ? 'Loading...' : 'Add'}
            </button>
        </form>
    )
}

const AddCard = ({ closePopup }) => {
    var stripePromise = loadStripe(global.storeConfigration?.public_key?.value)
    // useEffect(() => {
    //     stripePromise = loadStripe()
    // }, [global.storeConfigration?.public_key?.value])
    return (
        <div className="stripAdCard">
            {global.storeConfigration?.public_key?.value ? (
                <Elements stripe={stripePromise}>
                    <AddCardForm closePopup={closePopup} />
                </Elements>
            ) : null}
        </div>
    )
}
export default AddCard
